import * as React from "react"
import Svg, { Path, G, Circle, Defs, Use, ClipPath } from "react-native-svg"
const Section = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    style={{
      enableBackground: "new 0 0 500 500",
    }}
    viewBox="0 0 500 500"
    {...props}
  >
    <Path
      d="M117.183 42.446C95.689 48.074 75.08 61.693 57.474 80.745s-31.597 47.181-34.443 79.131c-2.93 32.899 6.037 65.668 17.573 94.147 11.536 28.478 17.94 53.499 27.457 83.559 8.373 26.447 12.981 55.733 24.263 79.709 15.363 32.649 41.69 51.462 68.355 57.016 26.665 5.554 61.722.296 87.897-9.045 54.537-19.462 106.859-51.916 154.968-94.537 21.78-19.295 43.259-41.468 57.26-72.754 15.824-35.358 20.574-81.062 12.658-121.796-6.483-33.362-21.643-64.211-41.583-82.848-8.508-7.955-93.603-108.768-314.696-50.881z"
      style={{
        fill: "#92e3a9",
      }}
    />
    <Path
      d="M460.426 297.835c-14.003 31.286-35.483 53.456-57.258 72.753-48.114 42.623-100.434 75.074-154.967 94.539-20.363 7.259-46.089 12.058-69.06 11.209-6.567-.237-12.917-.928-18.843-2.173-26.664-5.55-52.992-24.363-68.349-57.012a151.088 151.088 0 0 1-4.138-9.737c-8.424-21.904-12.858-47.008-20.126-69.968-9.52-30.061-15.919-55.086-27.464-83.567-11.535-28.471-20.502-61.248-17.569-94.144 2.844-31.947 16.838-60.073 34.446-79.133 17.598-19.05 38.208-32.668 59.708-38.297C337.9-15.585 422.988 85.234 431.501 93.184c19.939 18.635 35.098 49.486 41.586 82.856 7.91 40.727 3.16 86.431-12.661 121.795z"
      style={{
        opacity: 0.7,
        fill: "#fff",
      }}
    />
    <Path
      d="M276.895 452.084c-.145.228 1.064 1.436-3.03 1.497-10.083.15-27.877-.997-34.324-10.331-9.206-13.327 12.787-11.548 10.835-18.815-1.952-7.267-25.769-2-30.117-12.415-4.348-10.415 12.809-7.954 12.15-13.271-.659-5.317-18.895 1.071-24.559-10.004-5.664-11.075 9.955-4.819 7.518-9.41-2.436-4.59-21.965-23.949-13.743-27.934 8.222-3.985 26.522 16.169 32.671 18.436 6.149 2.267 8.091-16.978 16.078-10.311 7.987 6.667 1.869 35.634 11.287 39.498 7.014 2.878 3.423-7.656 12.156-7.025 11.862.856 14.696 41.772 3.078 60.085z"
      style={{
        fill: "#92e3a9",
      }}
    />
    <Path
      d="M276.895 452.084c-.145.228 1.064 1.436-3.03 1.497-10.083.15-27.877-.997-34.324-10.331-9.206-13.327 12.787-11.548 10.835-18.815-1.952-7.267-25.769-2-30.117-12.415-4.348-10.415 12.809-7.954 12.15-13.271-.659-5.317-18.895 1.071-24.559-10.004-5.664-11.075 9.955-4.819 7.518-9.41-2.436-4.59-21.965-23.949-13.743-27.934 8.222-3.985 26.522 16.169 32.671 18.436 6.149 2.267 8.091-16.978 16.078-10.311 7.987 6.667 1.869 35.634 11.287 39.498 7.014 2.878 3.423-7.656 12.156-7.025 11.862.856 14.696 41.772 3.078 60.085z"
      style={{
        opacity: 0.1,
      }}
    />
    <Path
      d="M277.126 453.711a2.91 2.91 0 0 0-.069-.267l-.178-.593a171.04 171.04 0 0 0-1.61-5.086 154.184 154.184 0 0 0-3.743-9.984 144.662 144.662 0 0 0-4.029-8.878 110.191 110.191 0 0 0-1.738-3.387c-3.694-6.992-7.545-12.838-11.14-17.618a113.7 113.7 0 0 0-6.982-8.394c-5.293-5.757-8.997-8.444-8.997-8.444"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M232.417 399.229c9.501-1.755 15.22.278 15.22.278M243.943 371.4s11.42 16.043 10.681 36.513M265.776 425.538s-8.223-8.184-19.566-4.452M271.526 437.779s4.704-10.259 2.724-24.96M280.128 452.358c.01-.109.02-.217.02-.326.049-.593.099-1.185.138-1.778.04-.375.069-.751.099-1.126.168-1.945.346-3.921.563-5.915.681-6.627 1.61-13.48 2.854-20.354 0-.03.01-.049.01-.079.158-.859.316-1.718.484-2.577 1.224-6.311 2.716-12.631 4.533-18.793 0-.01.01-.03.01-.039.306-1.037.632-2.074.948-3.101.267-.849.543-1.699.829-2.538a190.209 190.209 0 0 0 1.945-6.172v-.01a189.897 189.897 0 0 0 3.279-12.542v-.02c0-.01.01-.03.01-.04a152.986 152.986 0 0 0 1.471-7.219c1.866-10.201 2.548-19.159 2.617-26.644.039-3.881-.089-7.357-.306-10.419-.316-4.484-.83-8.049-1.294-10.626-.059-.316-.109-.622-.158-.918a1.6 1.6 0 0 1-.03-.109v-.02c-.01-.01-.01-.03-.01-.039-.02-.02-.01-.03 0-.049"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M298.179 321.126c-.014-.029-.014-.029-.028-.03-.014-.029-.014-.029-.028-.03a20.064 20.064 0 0 0-3.656-2.713 19.604 19.604 0 0 0-4.592-1.886"
      style={{
        fill: "#fff",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="m299.633 332.667 4.358-3.722M292.56 389.56v-.015a16.34 16.34 0 0 0-.903-3.298 16.711 16.711 0 0 0-2.406-4.37 16.99 16.99 0 0 0-2.585-2.711M288.82 401.415c1.258-2.939 3.812-5.316 6.833-6.36M295.845 377.005s1.51-25.866-12.433-35.665"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M295.949 436.509c.134 6.319-15.538 13.796-15.538 13.796s-15.974-6.807-16.108-13.126c-.134-6.319 6.778-14.621 15.516-14.806 8.74-.184 15.997 7.817 16.13 14.136zM312.077 359.764c-1.838 6.362-14.29 10.556-14.29 10.556s-8.298-10.188-6.461-16.55c1.838-6.362 8.853-13.228 14.583-11.573 5.731 1.656 8.005 11.204 6.168 17.567zM279.005 379.349c1.6 1.261 3.249 2.549 5.199 3.14 1.95.591 4.296.333 5.676-1.165 1.711-1.857 1.353-4.823.378-7.152-1.933-4.615-9.33-12.566-15.25-10.519-8.546 2.954.309 12.789 3.997 15.696zM295.964 391.46c-1.149 1.342-2.377 3.04-1.818 4.716.495 1.487 2.282 2.214 3.838 2.025 1.556-.189 2.929-1.068 4.242-1.923 3.045-1.984 9.288-6.153 5.502-10.333-3.934-4.345-9.342 2.687-11.764 5.515zM272.692 332.868c-1.281.104-2.6.921-2.854 2.18-.298 1.473.892 2.804 2.055 3.755 2.896 2.37 11.621 8.792 13.782 2.905 2.042-5.561-9.034-9.16-12.983-8.84zM289.677 313.792c-1.842.121-3.656-.599-5.501-.536-1.845.063-3.932 1.485-3.575 3.297.33 1.674 2.381 2.291 4.08 2.448 2.899.267 5.833.151 8.702-.346.666-.115 1.392-.291 1.788-.838.777-1.075-.365-2.58-1.621-3.005-1.256-.425-2.69-.338-3.822-1.029l-.051.009zM307.262 326.959c1.843.107 3.651-.626 5.497-.578 1.846.049 3.944 1.455 3.601 3.269-.317 1.677-2.364 2.309-4.061 2.479a33.17 33.17 0 0 1-8.704-.28c-.667-.11-1.395-.28-1.794-.825-.785-1.069.345-2.582 1.598-3.017 1.253-.435 2.688-.358 3.814-1.058l.049.01z"
      style={{
        fill: "#92e3a9",
      }}
    />
    <Path
      d="M298.183 321.346s-.63-7.372 1.165-7.922"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M296.852 313.604c1.187-2.468 2.469-5.051 4.731-6.596 2.261-1.545 5.813-1.563 7.36.697 1.73 2.526-.183 6.233-2.983 7.472-2.801 1.238-6.026.747-9.045.233l-.034-1.352-.029-.454zM288.923 440.545c8.139 1.57 23.401-3.597 30.155-10.026 6.854-6.524-9.582-4.587-6.958-10.188 2.624-5.601 21.142 2.083 26.157-5.721 5.015-7.804-9.224-8.277-7.927-12.486 1.297-4.208 15.55-.838 21.726-8.987 6.175-8.149-7.364-5.331-4.733-8.696 2.632-3.365 21.218-16.227 15.136-20.633-6.082-4.406-23.787 9.276-29.09 10.227-5.302.951-4.522-10.484-11.943-6.235-7.422 4.249-6.632 28.57-14.809 30.345-6.09 1.321-1.807-9.01-8.829-7.432-12.29 2.762-12.19 49.194-8.885 49.832z"
      style={{
        fill: "#92e3a9",
      }}
    />
    <G
      style={{
        opacity: 0.2,
      }}
    >
      <Path
        d="M288.923 440.545c8.139 1.57 23.401-3.597 30.155-10.026 6.854-6.524-9.582-4.587-6.958-10.188 2.624-5.601 21.142 2.083 26.157-5.721 5.015-7.804-9.224-8.277-7.927-12.486 1.297-4.208 15.55-.838 21.726-8.987 6.175-8.149-7.364-5.331-4.733-8.696 2.632-3.365 21.218-16.227 15.136-20.633-6.082-4.406-23.787 9.276-29.09 10.227-5.302.951-4.522-10.484-11.943-6.235-7.422 4.249-6.632 28.57-14.809 30.345-6.09 1.321-1.807-9.01-8.829-7.432-12.29 2.762-12.19 49.194-8.885 49.832z"
        style={{
          fill: "#fff",
        }}
      />
    </G>
    <Path
      d="m286.123 449.563.148-.385c.365-.958.741-1.906 1.126-2.844a132.89 132.89 0 0 1 2.439-5.639 144.028 144.028 0 0 1 4.642-9.323 163.399 163.399 0 0 1 12.127-19.05 172.576 172.576 0 0 1 6.844-8.69c14.102-16.769 26.901-25.607 26.901-25.607"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M338.142 396.701c-9.209-1.781-17.066.333-17.671.446-.03.016-.045.016-.045.016M326.524 372.895s-11.66 12.81-13.087 30.865M305.844 412.806s7.899-5.686 17.52-1.281M295.559 428.092s-3.09-9.532.14-22.218M66.933 103.255c13.883 0 25.134 11.251 25.134 25.129 0 13.883-11.251 25.135-25.134 25.135"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M66.933 153.518S79.5 147.455 79.5 128.384c0-19.102-12.567-25.129-12.567-25.129M66.933 153.518v-50.263"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M67.064 103.255c-13.883 0-25.134 11.251-25.134 25.129 0 13.883 11.251 25.135 25.134 25.135"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M67.064 153.518s-12.567-6.063-12.567-25.134c0-19.102 12.567-25.129 12.567-25.129M67.064 153.518v-50.263M66.933 103.255h.129M43.358 120.011h47.279M43.358 136.761h47.279M66.933 153.518h.129M465.431 220.412l5.04 6.125-3.718 3.135-5.127-6.226-5.967 4.758-6.766-24.705 22.668 12.025zM440.599 194.9l3.014 3.014M437.321 202.801h4.254M453.383 197.919l3.019-3.019M440.599 210.703l3.003-3.003M448.495 195.892v-4.265"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M359.385 317.976c.894 0 1.77.123 2.612.367.375.106.776.253 1.194.437a9.43 9.43 0 0 1 3.524 2.704 7.2 7.2 0 0 1 .619.882 9.269 9.269 0 0 1 1.48 5.028 9.21 9.21 0 0 1-1.095 4.373 4.39 4.39 0 0 1-1.911-.555 7.463 7.463 0 0 0 1.056-3.818 7.344 7.344 0 0 0-1.753-4.776 6.082 6.082 0 0 0-.802-.827 7.335 7.335 0 0 0-4.447-1.841c-.177-.024-.342-.024-.477-.024h-10.448c-4.118 0-7.469 3.35-7.469 7.468 0 4.124 3.35 7.478 7.469 7.478h9.23l.182.23c.497.617 1.06 1.188 1.673 1.699a9.657 9.657 0 0 1-.637.022h-10.448c-5.194 0-9.419-4.23-9.419-9.429 0-5.193 4.225-9.419 9.419-9.419h10.448m0-.999h-10.448c-5.748 0-10.419 4.681-10.419 10.419 0 5.748 4.671 10.429 10.419 10.429h10.448c1.116 0 2.182-.178 3.19-.504a11.165 11.165 0 0 1-3.447-2.844c-.158-.198-.316-.395-.454-.603h-9.737c-3.565 0-6.469-2.903-6.469-6.478a6.477 6.477 0 0 1 6.469-6.468h10.448c.128 0 .257 0 .375.02a6.29 6.29 0 0 1 3.881 1.59c.257.217.484.454.691.711a6.348 6.348 0 0 1 1.531 4.148 6.414 6.414 0 0 1-1.442 4.059 5.39 5.39 0 0 0 3.259 1.313h.059l.049.01c.039.01.079.01.118.01h.375a10.18 10.18 0 0 0 1.531-5.392c0-2.044-.602-3.96-1.639-5.57a8.272 8.272 0 0 0-.681-.968 10.384 10.384 0 0 0-3.901-2.992 10.273 10.273 0 0 0-4.206-.89z"
      style={{
        fill: "#263238",
      }}
    />
    <Path
      d="M378.454 317.887c5.194 0 9.419 4.225 9.419 9.419 0 5.194-4.225 9.419-9.419 9.419h-10.448a9.06 9.06 0 0 1-2.622-.377 8.881 8.881 0 0 1-1.174-.427 9.428 9.428 0 0 1-3.534-2.694 8.54 8.54 0 0 1-.629-.882 9.276 9.276 0 0 1-1.47-5.038c0-1.54.377-3.04 1.095-4.372h.009c.69.042 1.337.232 1.907.556a7.437 7.437 0 0 0-1.061 3.816c0 1.781.61 3.437 1.765 4.79.215.276.476.548.797.831a7.435 7.435 0 0 0 4.439 1.824c.177.024.342.024.477.024h10.448c4.118 0 7.469-3.35 7.469-7.469 0-4.118-3.35-7.468-7.469-7.468h-9.233a12.335 12.335 0 0 0-1.849-1.928 9.59 9.59 0 0 1 .634-.022h10.449m0-1.002h-10.448c-1.106 0-2.173.178-3.17.494a11.455 11.455 0 0 1 3.427 2.854c.158.198.306.395.444.602h9.747c3.565 0 6.469 2.903 6.469 6.468a6.478 6.478 0 0 1-6.469 6.469h-10.448c-.128 0-.257 0-.375-.02a6.397 6.397 0 0 1-3.881-1.58 5.078 5.078 0 0 1-.681-.711 6.33 6.33 0 0 1-1.541-4.158c0-1.531.543-2.943 1.442-4.049-.899-.8-2.024-1.254-3.259-1.323h-.059l-.049-.01c-.039-.01-.079-.01-.118-.01h-.375a10.18 10.18 0 0 0-1.531 5.392c0 2.054.593 3.97 1.629 5.58.208.336.445.662.691.968a10.392 10.392 0 0 0 3.911 2.982c.425.188.849.346 1.304.474.918.276 1.886.415 2.894.415h10.448c5.748 0 10.419-4.671 10.419-10.419-.002-5.747-4.673-10.418-10.421-10.418z"
      style={{
        fill: "#263238",
      }}
    />
    <Path
      d="M97.271 50.34h334.447V300.1H97.271z"
      style={{
        fill: "#999",
        stroke: "#263238",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M100.858 68.319H428.17v228.112H100.858z"
      style={{
        fill: "#fff",
      }}
    />
    <Path
      d="M97.166 50.34h334.447v14.048H97.166z"
      style={{
        fill: "#263238",
      }}
    />
    <Circle
      cx={109.479}
      cy={57.239}
      r={4.202}
      style={{
        fill: "#fff",
      }}
    />
    <Circle
      cx={122.362}
      cy={57.239}
      r={4.202}
      style={{
        fill: "#fff",
      }}
    />
    <Path
      d="M139.448 57.239a4.202 4.202 0 1 1-8.403 0 4.202 4.202 0 0 1 8.403 0z"
      style={{
        fill: "#fff",
      }}
    />
    <Path
      d="M303.141 237.131h-73.213c-5.909 0-10.699-4.79-10.699-10.699v-15.536c0-5.909 4.79-10.699 10.699-10.699h73.213c5.909 0 10.699 4.79 10.699 10.699v15.536c.001 5.909-4.789 10.699-10.699 10.699z"
      style={{
        fill: "#92e3a9",
        stroke: "#263238",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M228.634 240.311h76.528c6.599 0 11.949-5.35 11.949-11.949v-18.054"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M247.403 221.112c0 .863-.213 1.526-.64 1.988-.426.462-1.046.693-1.858.693-.813 0-1.432-.231-1.859-.693-.427-.462-.64-1.125-.64-1.988v-5.546c0-.863.213-1.526.64-1.988.427-.462 1.046-.693 1.859-.693s1.432.231 1.858.693c.427.462.64 1.125.64 1.988v1.036h-1.585v-1.143c0-.701-.289-1.051-.869-1.051-.579 0-.868.351-.868 1.051v5.759c0 .691.29 1.036.868 1.036.579 0 .869-.345.869-1.036v-1.524h1.585v1.418zM250.1 213.006v9.142h2.757v1.523h-4.434v-10.665h1.677zM255.265 213.006v10.665h-1.676v-10.665h1.676zM261.344 221.112c0 .863-.213 1.526-.64 1.988-.427.462-1.047.693-1.859.693s-1.432-.231-1.858-.693c-.427-.462-.64-1.125-.64-1.988v-5.546c0-.863.213-1.526.64-1.988.427-.462 1.046-.693 1.858-.693s1.433.231 1.859.693c.427.462.64 1.125.64 1.988v1.036h-1.585v-1.143c0-.701-.289-1.051-.868-1.051s-.868.351-.868 1.051v5.759c0 .691.289 1.036.868 1.036s.868-.345.868-1.036v-1.524h1.585v1.418zM264.04 220.395v3.276h-1.676v-10.665h1.676v4.647l2.194-4.647h1.676l-2.331 4.753 2.331 5.912h-1.722l-1.63-4.251-.518.975zM271.033 223.671v-10.665h1.676v4.571h1.904v-4.571h1.707v10.665h-1.707V219.1h-1.904v4.571h-1.676zM281.516 217.501v1.523h-2.301v3.124h2.896v1.523h-4.571v-10.665h4.571v1.523h-2.896v2.971h2.301zM286.528 223.184a7.113 7.113 0 0 1-.031-.822v-1.676c0-.487-.086-.836-.259-1.044-.173-.208-.452-.313-.838-.313h-.579v4.342h-1.676v-10.665h2.529c.863 0 1.493.201 1.89.602.396.401.594 1.019.594 1.851v.838c0 1.097-.365 1.818-1.097 2.163.396.163.68.435.853.815.173.381.26.866.26 1.455v1.645c0 .305.01.551.03.739.02.188.07.374.152.556h-1.707a2.445 2.445 0 0 1-.121-.486zm-1.707-5.378h.655c.335 0 .587-.087.754-.259.168-.172.252-.462.252-.868v-1.051c0-.386-.068-.666-.206-.838-.137-.172-.353-.259-.647-.259h-.808v3.275zM293.247 217.501v1.523h-2.301v3.124h2.896v1.523h-4.571v-10.665h4.571v1.523h-2.896v2.971h2.301z"
      style={{
        fill: "#263238",
      }}
    />
    <Path
      d="M220.229 117.951H368.29v14.08H220.229zM220.229 140.833h98.399v14.08h-98.399z"
      style={{
        fill: "#999",
      }}
    />
    <Path
      d="M220.229 167.231h98.399M220.229 176.182h107.586M220.229 185.134h116.774M402.487 75.759h18.602M402.487 80.805h18.602M402.487 85.85h18.602"
      style={{
        fill: "#999",
        stroke: "#263238",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M138.493 75.173h30.656v5.632h-30.656zM179.078 75.173h30.656v5.632h-30.656zM219.663 75.173h30.656v5.632h-30.656zM260.247 75.173h30.656v5.632h-30.656z"
      style={{
        fill: "#999",
      }}
    />
    <Path
      d="M106.981 75.173h22.36v22.36h-22.36z"
      style={{
        fill: "#fff",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
    />
    <Path
      d="m238.54 225.74-2.881 2.949M231.124 222.915l-.053 4.017M223.626 226.28l2.798 2.734M237.507 233.23l4.025-.145"
      style={{
        fill: "none",
        stroke: "#fff",
        strokeWidth: 2,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M138.094 210.394a59.604 59.604 0 0 1-2.691 27.395c-.742 2.152-1.988 4.576-4.243 4.887.116 3.112-3.294 6.696-6.378 7.127-1.594.223-2.915 2.302-4.007 3.484-2.08 2.25-5.71 2.882-8.427 1.467-2.718-1.415-4.281-4.752-3.629-7.746.633-2.907 2.174-5.143 4.149-7.368 3.935-4.431 6.468-11.483 8.718-16.964.78.143 1.266-14.479 1.765-20.436 2.007-1.354 10.344-.329 12.387.968 2.046 1.297 2.027 4.788 2.356 7.186z"
      style={{
        fill: "#263238",
      }}
    />
    <Defs>
      <Path
        id="a"
        d="M91.703 246.505c-.387.972-1.205 2.174-3.686 2.589 4.973 7.123 19.241 13.468 29.444 12.181 5.642-1.014 5.765-2.517 4.205-3.861-3.079-2.652-5.567-8.275-5.661-12.337l-.097-4.193-22.639-11.8s-1.125 11.953-1.166 15.294a5.965 5.965 0 0 1-.4 2.127z"
      />
    </Defs>
    <Use
      xlinkHref="#a"
      style={{
        overflow: "visible",
        fill: "#999",
      }}
    />
    <ClipPath id="b">
      <Use
        xlinkHref="#a"
        style={{
          overflow: "visible",
        }}
      />
    </ClipPath>
    <Path
      d="M90.708 243.728c-.192 1.454-.599 3.168-1.972 3.683-.674.253-1.557.206-1.943.815-.436.689.162 1.55.725 2.14 2.868 3.006 5.762 6.035 9.179 8.396 3.418 2.362 7.441 4.039 11.595 4.007-3.241-7.008-.525-16.218 5.999-20.347 1.583-1.002 3.632-2.289 3.247-4.123-.276-1.314-1.701-1.992-2.953-2.476l-10.233-3.957c-3.165-1.224-6.846-3.393-10.184-3.935-3.057-.496-2.206 3.316-2.385 5.585a195.402 195.402 0 0 1-1.075 10.212z"
      style={{
        opacity: 0.3,
        clipPath: "url(#b)",
        enableBackground: "new",
      }}
    />
    <Use
      xlinkHref="#a"
      style={{
        overflow: "visible",
        fill: "none",
        stroke: "#263238",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M107.751 242.806c2.287.725 8.263 2.517 8.263 2.518l.001-.001-.108-4.438v-.001l-22.638-11.8-.001.001h0c1.089 7.144 7.594 11.538 14.483 13.721z"
      style={{
        fill: "#263238",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
    />
    <Path
      d="M99.612 178.866c-6.84 4.41-12.604 9.056-15.141 23.399-2.537 14.343 3.477 24.164 3.477 24.164a37.65 37.65 0 0 0 5.105 6.744c3.891 4.351 20.796 9.934 25.741 9.609 4.945-.325 11.349-8.834 13.777-21.013s.074-15.036-3.383-30.622-11.85-17.103-11.85-17.103-8.388-1.218-17.726 4.822z"
      style={{
        fill: "#999",
      }}
    />
    <Defs>
      <Path
        id="c"
        d="M99.612 178.866c-6.84 4.41-12.604 9.056-15.141 23.399-2.537 14.343 3.477 24.164 3.477 24.164a37.65 37.65 0 0 0 5.105 6.744c3.891 4.351 20.796 9.934 25.741 9.609 4.945-.325 11.349-8.834 13.777-21.013s.074-15.036-3.383-30.622-11.85-17.103-11.85-17.103-8.388-1.218-17.726 4.822z"
      />
    </Defs>
    <ClipPath id="d">
      <Use
        xlinkHref="#c"
        style={{
          overflow: "visible",
        }}
      />
    </ClipPath>
    <G
      style={{
        clipPath: "url(#d)",
      }}
    >
      <Path
        d="M84.402 202.275c-2.537 14.343 3.477 24.164 3.477 24.164a37.615 37.615 0 0 0 5.105 6.744c3.891 4.351 20.855 9.925 25.741 9.609 1.75-.117 3.709-1.295 5.6-3.302-3.453.59-6.994.422-10.375-.495-7.829-2.142-16.527-9.976-18.863-22.536-1.873-10.114-5.392-22.114-6.69-26.431a40.397 40.397 0 0 0-3.995 12.247z"
        style={{
          opacity: 0.3,
          enableBackground: "new",
        }}
      />
    </G>
    <Path
      d="M99.612 178.866c-6.84 4.41-12.604 9.056-15.141 23.399-2.537 14.343 3.477 24.164 3.477 24.164a37.65 37.65 0 0 0 5.105 6.744c3.891 4.351 20.796 9.934 25.741 9.609 4.945-.325 11.349-8.834 13.777-21.013s.074-15.036-3.383-30.622-11.85-17.103-11.85-17.103-8.388-1.218-17.726 4.822z"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
    />
    <Path
      d="M122.027 165.695a59.736 59.736 0 0 1 15.736 9.613c.973.825.821 2.423-.349 2.93-1.544.669-3.335.786-4.932.248a80.215 80.215 0 0 1 20.487 22.785c.95 1.586-.263 3.663-2.106 3.518a9.333 9.333 0 0 1-3.101-.789c4.017 3.34 6.202 6.05 4.971 7.768-1.608.305-3.638-3.743-5.128-4.35 2.66 4.021 4.227 8.746 4.568 13.553.189 2.667-3.011 4.187-5.025 2.429l-.256-.223.673 7.173c.235 2.511-3.38 3.204-4.077.781a4.843 4.843 0 0 1-.061-.237c.985 2.212 1.995 4.551 1.395 7.005-.392 1.604-1.602 2.964-3.238 3.183-2.62.35-4.438-2.34-5.466-4.723 0 0 1.393-33.081-14.842-54.31-9.109-11.912-13.445-13.407-13.445-13.407.207-6.643 10.921-4.304 14.196-2.947z"
      style={{
        fill: "#263238",
      }}
    />
    <Path
      d="M113.463 167.245c-4.42-2.751-10.442-4.954-15.282-6.858-10.241-4.03-22.641-2.55-31.151 4.52-1.111.923-.707 2.723.69 3.091l2.728.718c-4.013.615-7.776 2.619-10.61 5.519-1.341 1.373.001 3.646 1.857 3.156l.051-.014c-7.983 4.693-13.965 12.602-16.515 21.488-.81 2.823 2.326 5.23 4.78 3.616a18.938 18.938 0 0 0 3.389-2.844 53.537 53.537 0 0 0-8.092 15.517c-.468 1.431-.85 3.121.365 4.267.84.793 2.11.902 3.183.474 1.925-.768 2.88-2.636 3.748-4.403a23.907 23.907 0 0 0-1.756 8.582c-.026 1.459 1.823 2.103 2.686.927l1.177-1.603c-2.546 5.731-1.722 12.778 1.912 17.861.636.889 1.969.848 2.515-.099l3.123-5.418c-.936 2.26-1.865 4.879-.754 7.059.999 1.962 4.44 2.217 5.262.471-.216 1.796 1.913 3.542 3.865 3.351 2.187-.215 3.912-1.884 5.446-3.458 11.184 7.956 16.972 6.403 18.3-5.54.352-3.162-2.376-4.181-6.432-11.197 0 0-4.756-9.175-2.242-14.403a15.823 15.823 0 0 1 2.304 3.721l3.432 5.314s-1.084-.747-.399-6.729c.379-3.305 1.565-9.068 3.167-14.272 7.713-25.052 32.425-14.839 31.996-11.801.001 0-3.109-15.018-12.743-21.013z"
      style={{
        fill: "#263238",
      }}
    />
    <Path
      d="M116.715 228.692c2.024 1.851 7.44 1.853 7.657-1.419 0 0-.352-1.839-1.584-1.373"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
    />
    <Path
      d="M116.015 226.484c2.379.124 5.393.155 7.511-.936.522-.269.889-1.164.692-1.718a1.302 1.302 0 0 0-1.4-.82M117.5 218.913s1.332 1.725 4.887-.316c3.555-2.041.879-5.499-1.517-8.57a7.735 7.735 0 0 1-1.24-6.684"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
    />
    <Path
      d="M106.621 207.926c.771-.118 1.582 1.007 1.812 2.511.229 1.503-.209 2.819-.98 2.937-.771.118-1.582-1.007-1.812-2.511-.233-1.523.209-2.819.98-2.937zM124.896 206.874c.23 1.504-.209 2.819-.98 2.937-.771.118-1.582-1.007-1.812-2.511-.229-1.504.21-2.819.98-2.937.771-.117 1.582 1.007 1.812 2.511z"
      style={{
        fill: "#263238",
      }}
    />
    <Path
      d="M100.83 205.807c1.764-6.717 13.24-2.832 13.24-2.832M120.667 201.809c4.59-5.673 7.574-2.136 7.574-2.136"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
    />
    <Path
      d="M89.62 224.885s-3.284 2.439-6.648-1.343-5.865-10.284-1.954-12.809c3.91-2.525 7.869 6.531 7.869 6.531l.733 7.621z"
      style={{
        fill: "#999",
      }}
    />
    <Defs>
      <Path
        id="e"
        d="M89.62 224.885s-3.284 2.439-6.648-1.343-5.865-10.284-1.954-12.809c3.91-2.525 7.869 6.531 7.869 6.531"
      />
    </Defs>
    <ClipPath id="f">
      <Use
        xlinkHref="#e"
        style={{
          overflow: "visible",
        }}
      />
    </ClipPath>
    <G
      style={{
        clipPath: "url(#f)",
      }}
    >
      <Path
        d="M89.62 224.885s-3.284 2.439-6.648-1.343-5.865-10.284-1.954-12.809c3.91-2.525 7.869 6.531 7.869 6.531"
        style={{
          opacity: 0.3,
          enableBackground: "new",
        }}
      />
    </G>
    <Path
      d="M89.62 224.885s-3.284 2.439-6.648-1.343-5.865-10.284-1.954-12.809c3.91-2.525 7.869 6.531 7.869 6.531"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
    />
    <Path
      d="M134.051 265.48c-16.422-5.088-35.553-10.853-46.895-16.825 0 0 2.38-6.264 4.587-7.251 0 0 5.404-.193 14.143 3.249 11.614 4.574 27.816 20.153 28.165 20.827z"
      style={{
        fill: "#fff",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
    />
    <Defs>
      <Path
        id="g"
        d="M105.595 365.298c-2.545 3.305-3.893 6.779-6.428 10.085-11.239-4.842-26.736-19.849-39.309-28.584-10.712-7.442-6.191-18.882-6.19-18.902.439-1.99 16.296-61.051 17.445-63.756 3.036-7.133 7.943-16.517 15.482-14.681 22.236 5.422-9.758 83.265-9.758 83.265 1.305 1.215 19.731 22.415 28.758 32.573z"
      />
    </Defs>
    <Use
      xlinkHref="#g"
      style={{
        overflow: "visible",
        fill: "#92e3a9",
      }}
    />
    <ClipPath id="h">
      <Use
        xlinkHref="#g"
        style={{
          overflow: "visible",
        }}
      />
    </ClipPath>
    <Path
      d="M71.996 262.857a139.77 139.77 0 0 0 4.961 36.142l-4.271-5.159c1.335 7.546 2.675 15.203 1.95 22.831-.219 2.302-.685 4.715-2.211 6.453-1.526 1.738-4.417 2.457-6.184.965 1.89-.333 2.99 2.346 2.89 4.262-.1 1.917-2.235 3.441-4.08 2.912-.608 1.203 1.067 2.358 2.414 2.314 1.347-.043 2.817-.559 3.964.149.94.58 1.273 1.762 1.552 2.831a80.17 80.17 0 0 0 18.128 33.485c-12.971-9.07-26.514-18.565-38.076-29.374-4.21-3.936-8.677-9.048-7.525-14.695 4.592-22.492 16.595-42.401 26.488-63.116z"
      style={{
        opacity: 0.3,
        clipPath: "url(#h)",
        enableBackground: "new",
      }}
    />
    <Use
      xlinkHref="#g"
      style={{
        overflow: "visible",
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
    />
    <Path
      d="M179.141 476.336c-6.567-.237-12.917-.928-18.843-2.173-26.664-5.55-52.992-24.363-68.349-57.012a151.088 151.088 0 0 1-4.138-9.737 3.42 3.42 0 0 0 .014-.579c-.402-8.799 1.001-17.592 3.699-25.977l73.243-2.834a371.804 371.804 0 0 0 9.443 76.928l4.931 21.384z"
      style={{
        fill: "#263238",
      }}
    />
    <Defs>
      <Path
        id="i"
        d="M141.456 271.136c10.21 10.003 18.214 27.727 16.913 41.962-1.768 19.354-.189 7.768 2.729 26.983 3.82 25.158 4.36 54.973 4.36 54.973-26.972 7.161-60.101 2.588-75.347-11.706-.541-25.367 10.914-36.669 1.002-60.026-5.958-14.04-15.888-29.773-17.764-44.909-1.379-11.131-2.644-19.76 3.594-25.592 2.738-2.559 6.401-3.875 10.146-4.02 33.604-1.298 54.367 22.335 54.367 22.335z"
      />
    </Defs>
    <Use
      xlinkHref="#i"
      style={{
        overflow: "visible",
        fill: "#92e3a9",
      }}
    />
    <ClipPath id="j">
      <Use
        xlinkHref="#i"
        style={{
          overflow: "visible",
        }}
      />
    </ClipPath>
    <Path
      d="M109.479 277.568c5.129 10.458 7.899 18.134 11.13 29.325l-19.985-11.634a90.975 90.975 0 0 0 25.241 26.109c-1.671.445-3.519 2.496-3.897 4.183-.379 1.687-.113 3.447.22 5.144a68.357 68.357 0 0 0 15.021 31.096c-5.823-2.489-10.99-6.357-15.32-10.977-6.915-7.377-13.825-14.767-19.785-22.934-.792-1.085-2.903-2.402-4.215-2.113 3.61 18.663 16.352 35.335 33.412 43.718-3.11 1.726-7.67 2.691-10.776.958-3.106-1.733-5.523-4.45-7.877-7.116l-11.047-12.509c2.493 16.231 13.19 31.905 28.315 38.3 4.453 1.883 8.852 5.189 11.662 9.123-18.175 3.05-36.967-3.519-51.466-14.895-.627-13.028 4.597-25.051 5.048-42.008.264-9.94-7.869-27.749-13.77-38.576-5.137-9.425-8.061-20.783-9.169-31.459-.303-2.922-1.188-9.863 4.724-18.484 1.66-2.419 14.798-11.415 32.534 24.749z"
      style={{
        opacity: 0.3,
        clipPath: "url(#j)",
        enableBackground: "new",
      }}
    />
    <Use
      xlinkHref="#i"
      style={{
        overflow: "visible",
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
    />
    <Path
      d="M228.991 272.184c-.114.075-.229.147-.345.217-2.073 1.242-3.743 3.056-4.916 5.168l-14.385-7.754c.416-1.792 2.058-3.718 1.128-5.463-1.091-2.048-1.824-4.28-1.716-6.599.057-1.237.183-2.493.335-3.755.403-3.342.115-6.705-.447-10.023-.082-.482-.29-1.522-.163-2.039.203-.824 1.018-1.46 1.849-1.633 1.415-.294 2.644 1.065 3.229 2.225.533 1.057.688 2.521.999 3.662.508 1.861.406 4.183 1.492 5.706.587.824 2.478.702 2.957-.188 1.77-3.283 9.561-18.16 9.561-18.16.387-.763 1.025-1.398 1.836-1.673 2.628-.888 4.296 1.915 2.995 4.328l-8.374 15.342 4.463-8.18s1.035-1.379 2.838.042c1.045.824 1.511 1.674.786 3.018l-3.988 7.399 3.45-6.307s1.326-1.013 2.761.176c1.435 1.189 1.117 2.678 1.117 2.678l-3.815 7.077 2.254-4.182s.949-.734 2.611.162c1.662.896 1.302 2.847 1.211 3.144-.071.232-4.482 8.36-6.403 11.895-.804 1.48-1.911 2.792-3.32 3.717z"
      style={{
        fill: "#999",
        stroke: "#263238",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="m205.475 272.752 2.781-8.109s13.088-.538 18.771 9.536l-8.33 9.004c0 .001.198-7.246-13.222-10.431z"
      style={{
        fill: "#fff",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
    />
    <Path
      d="M114.922 285.834s3.239 22.161 24.527 43.649c11.687 11.797 21.65 10.599 21.65 10.599s-28.526-17.578-46.177-54.248z"
      style={{
        fill: "#263238",
      }}
    />
    <Defs>
      <Path
        id="k"
        d="M220.813 280.896c-10.074 19.433-19.071 34.954-33.096 51.761-2.943 3.526-6.133 6.988-10.192 9.138-4.058 2.15-7.399 3.219-11.419.998-19.522-10.786-35.945-27.11-46.848-46.567-4.084-7.288-7.354-16.527-3.037-23.68 3.391-5.619 11.725-9.475 17.83-7.066 4.721 1.863 7.99 6.136 10.969 10.244 5.732 7.903 21.462 33.048 23.896 36.746 12.504-12.526 25.018-26.299 36.559-39.718 2.261-2.628 13.431 3.162 15.338 8.144z"
      />
    </Defs>
    <Use
      xlinkHref="#k"
      style={{
        overflow: "visible",
        fill: "#92e3a9",
      }}
    />
    <ClipPath id="l">
      <Use
        xlinkHref="#k"
        style={{
          overflow: "visible",
        }}
      />
    </ClipPath>
    <Path
      d="M180.195 340.115c3.678-2.021 6.454-5.805 7.911-9.741-2.86 1.878-5.938 3.79-9.359 3.852s-7.527-3.108-7.328-6.524c.582.212.496 1.196-.067 1.455-.563.26-1.256-.081-1.6-.597-.344-.516-.424-1.158-.495-1.774-.307-2.651-1.01-5.589-1.317-8.24-1.365 3.744-1.346 2.746-2.711 6.49-.328.901-.684 1.841-1.406 2.472-.723.63-1.955-.291-2.725-.863-3.353-2.489-12.141-14.193-22.46-26.546-9.215-11.032-19.799-30.711-24.456-22.532 0 0-.112 8.266 5.993 20.195 9.848 19.244 32.162 40.723 51.274 46.362 3.407 1.006 4.64-1.753 8.746-4.009z"
      style={{
        opacity: 0.3,
        clipPath: "url(#l)",
        enableBackground: "new",
      }}
    />
    <Use
      xlinkHref="#k"
      style={{
        overflow: "visible",
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
    />
    <Path
      d="M94.005 196.475c-1.819 5.11-2.011 8.782-.641 14.03-1.871 7.917 1.02 11.021 3.466 11.283-.437 1.146-.754 2.911-.847 4.134-.093 1.223.209 2.533 1.08 3.398.87.865 2.378 1.126 3.348.375-.135 1.194-.227 2.599-.013 3.781s.83 2.349 1.874 2.943c1.044.594 2.542.441 3.249-.53-2.334 5.192 3.173 7.419 3.638 6.925-.537.043-.995-.533-1.17-1.126-1.069-3.63 1.352-5.116 1.278-8.625-.101-4.775-4.371-3.857-4.164-2.823.156-.546 1.031-10.124-3.497-9.286-.761.141 2.75-7.498-1.663-8.597-.777-.193-1.771.439-1.547 1.208 6.881-34.587 25.663-27.501 25.663-27.501-5.748-6.222-24.022-6.535-30.054 10.411z"
      style={{
        fill: "#263238",
      }}
    />
    <Circle
      cx={87.784}
      cy={230.446}
      r={2.549}
      style={{
        fill: "#999",
        stroke: "#263238",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M107.137 251c8.669 3.039 14.023 6.746 20.137 13.603"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
    />
    <Path
      d="M170.169 313.717a31.296 31.296 0 0 0-.815 13.184"
      style={{
        fill: "#92e3a9",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
    />
    <Path
      d="M171.495 322.334a18.552 18.552 0 0 0 2.155 10.042M86.008 312.305a120.293 120.293 0 0 1 14.142 34.355M124.993 313.304a74.428 74.428 0 0 1-8.984-5.308M92.263 281.533c4.713 8.775 11.21 16.574 18.937 22.857M145.967 341.717l6.37 55.841M144.662 330.273l.85 7.451M74.78 333.087c-5.469 1.42-11.431.855-16.536-1.566 3.27-1.565 7.209-1.663 10.554-.263M62.489 320.77c2.411 3.966 6.687 6.742 11.291 7.329M71.032 290.485l10.357 13.867"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
    />
    <Path
      d="M107.82 425.019c6.125 9.829 16.156 20.299 26.21 24.938M104.277 417.639a55.598 55.598 0 0 0 1.643 4.008M120.804 440.829l4.189 16.128"
      style={{
        fill: "none",
        stroke: "#999",
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
    />
    <Path
      d="M98.314 320.772c8.968 16.853 22.201 31.439 38.184 41.89M92.84 308.854a111.324 111.324 0 0 0 3.057 7.103"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
    />
    <Path
      d="m141.314 297.701 5.922-7.092M133.895 297.956c4.397-1.41 8.217-4.541 10.463-8.575"
      style={{
        fill: "#92e3a9",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
    />
  </Svg>
)
export default Section
