import * as React from "react"
import Svg, { Path, Defs, Use, ClipPath, G, Circle } from "react-native-svg"
const SectionThree = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    style={{
      enableBackground: "new 0 0 500 500",
    }}
    viewBox="0 0 500 500"
    {...props}
  >
    <Path
      d="M67.931 167.086s-27.877 71.988 11.297 144.503c39.174 72.516 119.879 110.802 184.469 143.548 64.59 32.746 131.661 16.799 163.534-36.838 31.873-53.637-11.845-91.928-11.897-168.418-.051-76.49 11.447-96.742-29.963-161.364-41.41-64.623-143.059-78.925-219.9-30.996-76.84 47.929-97.54 109.565-97.54 109.565z"
      style={{
        opacity: 0.7,
        fill: "#fff",
      }}
    />
    <Path
      d="M66.521 95.238A98.504 98.504 0 0 1 77.508 84.04c28.471-24.659 69.157-29.356 104.323-18.299 35.166 11.057 65.255 36.146 89.62 65.393 11.132 13.363 21.708 28.076 36.606 36.248 19.211 10.538 41.465 8.329 62.233 11.43 75.519 11.274 100.131 117.192 48.531 172.305-20.341 21.725-48.734 33.977-75.648 43.174-37.281 12.74-76.281 21.087-115.397 19.527-39.116-1.56-78.476-13.501-109.98-38.309-41.261-32.49-66.069-84.674-76.395-138.296-5.226-27.137-7.082-55.375-1.898-82.521 4.168-21.824 13.176-42.944 27.018-59.454z"
      style={{
        fill: "#92e3a9",
      }}
    />
    <Path
      d="M370.29 178.812c-20.769-3.1-43.022-.892-62.233-11.43-14.898-8.172-25.474-22.886-36.606-36.248-24.366-29.248-54.455-54.336-89.62-65.393-35.166-11.057-75.853-6.36-104.323 18.299a98.587 98.587 0 0 0-10.987 11.198c-13.842 16.511-22.85 37.63-27.018 59.455-5.184 27.146-3.328 55.384 1.898 82.521 10.326 53.622 35.134 105.806 76.395 138.296 31.505 24.807 70.864 36.749 109.98 38.309 39.116 1.56 78.116-6.787 115.397-19.527 26.914-9.198 55.307-21.449 75.648-43.174 51.601-55.114 26.988-161.032-48.531-172.306z"
      style={{
        opacity: 0.7,
        fill: "#fff",
      }}
    />
    <Path
      d="M36.424 442.344h428.378"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M251.551 119.795v3.5"
      style={{
        fill: "none",
        stroke: "#92e3a9",
        strokeWidth: 3,
        strokeLinejoin: "round",
      }}
    />
    <Path
      d="M251.551 130.979v11.527"
      style={{
        fill: "none",
        stroke: "#92e3a9",
        strokeWidth: 3,
        strokeLinejoin: "round",
        strokeDasharray: "7.6847,7.6847",
      }}
    />
    <Path
      d="M251.551 146.349v3.5h-3.5"
      style={{
        fill: "none",
        stroke: "#92e3a9",
        strokeWidth: 3,
        strokeLinejoin: "round",
      }}
    />
    <Path
      d="M240.982 149.849H131.424"
      style={{
        fill: "none",
        stroke: "#92e3a9",
        strokeWidth: 3,
        strokeLinejoin: "round",
        strokeDasharray: "7.0683,7.0683",
      }}
    />
    <Path
      d="M127.89 149.849h-3.5v3.5"
      style={{
        fill: "none",
        stroke: "#92e3a9",
        strokeWidth: 3,
        strokeLinejoin: "round",
      }}
    />
    <Path
      d="M124.39 159.089v8.61"
      style={{
        fill: "none",
        stroke: "#92e3a9",
        strokeWidth: 3,
        strokeLinejoin: "round",
        strokeDasharray: "5.7402,5.7402",
      }}
    />
    <Path
      d="M124.39 170.569v3.5"
      style={{
        fill: "none",
        stroke: "#92e3a9",
        strokeWidth: 3,
        strokeLinejoin: "round",
      }}
    />
    <Path
      d="M142.165 136.413h14.207M145.285 102.258c-4.326 2.06-7.316 6.472-7.316 11.582 0 1.457.255 2.852.702 4.157a9.063 9.063 0 0 0-.702-.035 9.226 9.226 0 0 0 0 18.452M173.275 91.477a18.474 18.474 0 0 0-5.561-.852c-7.312 0-13.631 4.248-16.627 10.409-.1-.002-.197-.015-.298-.015M188.321 106.517c-.778 0-1.543.06-2.289.175a18.464 18.464 0 0 0-6.727-11.978M165.286 136.413h23.035c8.255 0 14.948-6.692 14.948-14.948M321.354 137.239h9.638M323.471 114.067a8.7 8.7 0 0 0-4.964 7.858c0 .988.173 1.935.477 2.82a6.268 6.268 0 0 0-.477-.024 6.259 6.259 0 1 0 0 12.518M342.46 106.753a12.535 12.535 0 0 0-3.772-.578c-4.961 0-9.248 2.882-11.28 7.062-.068-.002-.134-.01-.202-.01M352.668 116.956c-.528 0-1.047.041-1.553.118a12.526 12.526 0 0 0-4.564-8.126M337.04 137.239h15.628c5.601 0 10.141-4.54 10.141-10.141"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M285.058 70.911c-1.223 0-2.424.094-3.596.274-1.858-14.242-14.029-25.242-28.778-25.242-11.488 0-21.415 6.674-26.122 16.354-.157-.004-.31-.024-.468-.024-11.125 0-20.143 9.018-20.143 20.143 0 2.289.4 4.48 1.103 6.531a14.362 14.362 0 0 0-1.103-.056c-8.005 0-14.494 6.489-14.494 14.494s6.489 14.494 14.494 14.494h79.107c12.97 0 23.485-10.514 23.485-23.485s-10.514-23.483-23.485-23.483z"
      style={{
        fill: "#fff",
        stroke: "#263238",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M251.674 94.706V77.901M260.999 83.618l-9.325-9.324-9.324 9.324M251.674 98.473v3.785M251.674 106.692v2.895M221.703 442.344h-19.811c-4.873 0-8.196-3.94-7.421-8.8l7.668-48.083c.775-4.86 5.354-8.8 10.228-8.8h60.765"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M210.626 363.32h17.799c1.109 0 2.103.68 2.505 1.714l2.119 5.449h37.7a8.829 8.829 0 0 1 8.361 5.994l2.115.185c4.873 0 8.196 3.94 7.421 8.8l-7.668 48.083c-.775 4.86-5.354 8.8-10.228 8.8h-37.574M193.068 400.319v-21.012c0-4.658 3.62-8.432 8.194-8.761l2.144-5.513a2.689 2.689 0 0 1 2.505-1.714M201.892 442.344a8.824 8.824 0 0 1-8.824-8.824v-19.515"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Defs>
      <Path
        id="a"
        d="M390.502 442.344a4.452 4.452 0 0 1-4.452-4.452v-27.353c0-2.35 1.827-4.254 4.134-4.42l1.082-2.782a1.358 1.358 0 0 1 1.264-.865h11.36c.559 0 1.061.343 1.264.865l1.069 2.75h19.022a4.455 4.455 0 0 1 4.218 3.024"
      />
    </Defs>
    <Use
      xlinkHref="#a"
      style={{
        overflow: "visible",
        fill: "#92e3a9",
      }}
    />
    <ClipPath id="b">
      <Use
        xlinkHref="#a"
        style={{
          overflow: "visible",
        }}
      />
    </ClipPath>
    <Path
      d="M390.502 442.344a4.452 4.452 0 0 1-4.452-4.452v-27.353c0-2.35 1.827-4.254 4.134-4.42l1.082-2.782a1.358 1.358 0 0 1 1.264-.865h11.36c.559 0 1.061.343 1.264.865l1.069 2.75h19.022a4.455 4.455 0 0 1 4.218 3.024"
      style={{
        opacity: 0.3,
        clipPath: "url(#b)",
        fillOpacity: 0.7,
      }}
    />
    <Use
      xlinkHref="#a"
      style={{
        overflow: "visible",
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M390.502 442.344h34.742c2.459 0 4.769-1.988 5.16-4.44l3.869-24.26c.391-2.452-1.285-4.44-3.744-4.44h-34.742c-2.459 0-4.769 1.988-5.16 4.44l-3.869 24.26c-.391 2.452 1.285 4.44 3.744 4.44z"
      style={{
        fill: "#92e3a9",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Defs>
      <Path
        id="c"
        d="M188.089 442.344a4.452 4.452 0 0 1-4.452-4.452v-27.353c0-2.35 1.826-4.254 4.134-4.42l1.082-2.782a1.358 1.358 0 0 1 1.264-.865h11.36c.559 0 1.061.343 1.264.865l1.069 2.75h19.021a4.454 4.454 0 0 1 4.218 3.024"
      />
    </Defs>
    <Use
      xlinkHref="#c"
      style={{
        overflow: "visible",
        fill: "#92e3a9",
      }}
    />
    <ClipPath id="d">
      <Use
        xlinkHref="#c"
        style={{
          overflow: "visible",
        }}
      />
    </ClipPath>
    <Path
      d="M188.089 442.344a4.452 4.452 0 0 1-4.452-4.452v-27.353c0-2.35 1.826-4.254 4.134-4.42l1.082-2.782a1.358 1.358 0 0 1 1.264-.865h11.36c.559 0 1.061.343 1.264.865l1.069 2.75h19.021a4.454 4.454 0 0 1 4.218 3.024"
      style={{
        opacity: 0.3,
        clipPath: "url(#d)",
        fillOpacity: 0.7,
      }}
    />
    <Use
      xlinkHref="#c"
      style={{
        overflow: "visible",
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M188.089 442.344h34.742c2.459 0 4.769-1.988 5.16-4.44l3.869-24.26c.391-2.452-1.285-4.44-3.744-4.44h-34.742c-2.459 0-4.769 1.988-5.16 4.44l-3.869 24.26c-.391 2.452 1.285 4.44 3.744 4.44zM310.354 428.211s1.571 9.634 0 14.133h-31.876s-1.347-3.372 0-5.497 11.449-3.342 14.591-6.213c3.143-2.871 6.51-7.81 6.51-7.81l10.775 5.387z"
      style={{
        fill: "#92e3a9",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M277.879 440.646h32.892l.282 1.698h-33.174z"
      style={{
        fill: "#fff",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M302.692 427.037s-3 .725-3.337.837c-.337.112-3.255 4.602-6.173 5.388-2.918.786-5.837.635-5.388 2.057M296.05 427.037l3.417 2.521M294.466 429.245l3.292 2.418M292.362 431.178l2.858 2.757M289.475 432.584l1.237 2.735"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M310.868 432.584s-5.228-.637-10.952 1.368c-5.724 2.004-7.699 3.758-7.553 4.284.146.526.819 2.091.819 2.091h2.868s-1.036-1.832-.83-2.091c.206-.259 9.989-4.076 15.648-3.402v-2.25z"
      style={{
        fill: "#263238",
      }}
    />
    <Path
      d="M364.375 442.344s1.228-4.69-.208-6.428c-2.579-3.121.315-5.737-2.274-6.822l-1.085 1.259c-.211-1.278.393-5.161-4.408-5.667-5.804-.612-5.914 3.612-6.227 5.003l.095-1.944c-2.474 0-1.192 2.471-4.944 6.512-2.864 3.084-2.869 7.038-2.711 8.087h21.762z"
      style={{
        fill: "#92e3a9",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M385.405 263.943c2.677 1.562 9.587 16.277 5.555 45.49-4.033 29.213-16.115 86.339-18.138 95.076-2.024 8.738-9.123 29.748-9.123 29.748s-2.714-9.116-15.744-4.243a.666.666 0 0 1-.882-.753l2.863-14.888s.2-10.891 1.137-13.621c3.53-10.286 5.297-50.009 6.462-55.553 1.164-5.545-1.124-21.492-1.124-21.492s-16.315 39.036-16.842 42.866c-.527 3.829-10.477 19.774-10.477 19.774L327.38 401s-13.237 22.684-15.568 29.454l-15.763-3.416.438-11.212 4.246-1.452 7.419-25.492s2.796-11.284 8.633-26.017c3.512-8.866 13.462-43.194 13.462-43.194 3.093-3.36 6-21.937 6-21.937l4.778-23.435 44.38-10.356z"
      style={{
        fill: "#263238",
      }}
    />
    <Path
      d="m319.223 201.996-32.405 24.4-20.846-32.892-7.799 1.01s15.525 42.426 24.112 46.65c8.587 4.224 40.966-19.227 40.966-19.227"
      style={{
        fill: "#fff",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Defs>
      <Path
        id="e"
        d="M326.342 197.123s-9.377 3.27-15.671 8.721c-6.294 5.451-16.319 13.589-16.319 13.589s5.675 13.52 12.209 19.1l13.97-5.604 5.811-35.806z"
      />
    </Defs>
    <Use
      xlinkHref="#e"
      style={{
        overflow: "visible",
        fill: "#92e3a9",
      }}
    />
    <ClipPath id="f">
      <Use
        xlinkHref="#e"
        style={{
          overflow: "visible",
        }}
      />
    </ClipPath>
    <Path
      d="m306.474 238.456 5.119-4.55c-3.544-1.187-3.956-8.407-1.978-6.33s5.341 2.769 5.341 2.769c-2.572-7.121-1.534-13.912-1.534-13.912s1.71 4.772 2.689 9.57c.654 3.202 3.038 5.347 4.015 6.485l6.215-35.365s-9.377 3.27-15.671 8.721c-6.294 5.451-16.319 13.589-16.319 13.589s5.626 13.398 12.123 19.023z"
      style={{
        opacity: 0.4,
        clipPath: "url(#f)",
        fill: "#fff",
      }}
    />
    <Use
      xlinkHref="#e"
      style={{
        overflow: "visible",
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Defs>
      <Path
        id="g"
        d="M320.53 232.929c5.447 13.343 13.971 30.016 13.971 30.016l3.768 18.59s14.161 10.792 30.396 6.268c19.044-5.306 22.119-17.339 22.119-17.339l-7.246-14.554c-2.477-13.979-5.715-47.047-16.869-54.595-11.154-7.548-30.29-8.193-38.013-5.135-6.87 2.721-10.672 4.788-12.545 7.302"
      />
    </Defs>
    <Use
      xlinkHref="#g"
      style={{
        overflow: "visible",
        fill: "#92e3a9",
      }}
    />
    <ClipPath id="h">
      <Use
        xlinkHref="#g"
        style={{
          overflow: "visible",
        }}
      />
    </ClipPath>
    <Path
      d="M379.874 274.954c-17.169 5.542-15.27 1.579-11.347-5.344 3.549-6.263 1.036-13.571 1.095-15.542-.775 1.518-3.693 5.958-7.309 7.696-4.664 2.242-3.02 2.968 1.072-4.814 4.092-7.781 5.142-16.615 5.142-16.615-4.582-4.549-8.191-15.653-8.191-15.653l15.027-9.191c-2.212-6.427-5.022-11.688-8.695-14.174-11.154-7.548-30.29-8.193-38.013-5.135-6.87 2.721-10.672 4.788-12.545 7.302l4.419 29.446c5.447 13.343 13.971 30.016 13.971 30.016l3.768 18.59s14.161 10.792 30.396 6.268c19.044-5.306 22.119-17.339 22.119-17.339l-.276-.555c-3.15 1.998-6.704 3.775-10.633 5.044z"
      style={{
        opacity: 0.4,
        clipPath: "url(#h)",
        fill: "#fff",
      }}
    />
    <Use
      xlinkHref="#g"
      style={{
        overflow: "visible",
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M346.71 198.76s-4.18 7.76-11.61 7.04c-6.18-.61-6-5.59-6-5.59s1.34-1.02 1.86-2.83c.16-.54.24-1.16.19-1.83-.1-1.54-.88-3.42-3.02-5.57l-.1-.1c-.46-.46-.83-.93-1.09-1.4-3.41-6.03 8.49-12.79 17.47-7.8 0 0-6.96 5.37 2.3 18.08z"
      style={{
        fill: "#fff",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M333.59 188.23s-.17 4.67-2.44 7.32c-.1-1.54-.88-3.42-3.02-5.57 1.2-1.32 3.01-2.16 5.46-1.75z"
      style={{
        fill: "#263238",
      }}
    />
    <Path
      d="M345.817 174.911c3.367-14.432-3.608-20.685-8.178-22.576-7.321-3.029-16.837 1.732-19.001 9.749-2.165 8.017-.214 9.842-.315 13.122-.071 2.3-1.489 3.433.074 9.206 1.031 3.808 2.747 9.92 9.26 8.825 1.298-.218 3.405-.733 5.755-1.873"
      style={{
        fill: "#fff",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M326.815 170.221s2.707-1.006 4.153 2.169M317.613 168.537s2.828-.608 4.031 1.44M322.366 174.069s-2.781 4.821-2.737 5.482c.137 2.058 2.764 1.132 2.764 1.132"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M327.353 174.734c0 .671.301 1.214.671 1.214.371 0 .671-.544.671-1.214 0-.671-.301-1.214-.671-1.214-.37-.001-.671.543-.671 1.214zM319.627 174.069c0 .671.301 1.214.671 1.214.371 0 .671-.544.671-1.214 0-.671-.3-1.215-.671-1.215-.371.001-.671.544-.671 1.215z"
      style={{
        fill: "#263238",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M327.353 182.661s-2.95 1.637-5.709.922"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M326.815 183.213s-.317 2.134-2.316 2.341c-1.999.207-2.106-1.789-2.106-1.789"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M345.836 156.492s-.437-7.737-14.868-7.46c-14.431.277-18.645 11.43-11.34 14.454 7.305 3.024 11.842 1.701 11.842 1.701s-2.697-.798-5.129-3.518c0 0 5.048 2.96 9.342 3.806 0 0-1.647 2.156 0 5.754 1.647 3.598.841 5.137.841 5.137s6.606 1.878 5.502 8.959c0 0 6.079-.115 8.536-14.096s-4.726-14.737-4.726-14.737z"
      style={{
        fill: "#263238",
      }}
    />
    <Path
      d="M337.639 177.436s2.646-4.931 5.412-3.728c2.766 1.203.842 7.095-1.563 9.02-2.405 1.924-3.848 0-3.848 0"
      style={{
        fill: "#fff",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M313.422 216.434c1.611 6.485 2.445 11.595 7.109 16.496M312.41 211.27a41.45 41.45 0 0 0 .52 3.008"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M368.527 228.871s19.298 20.863 24.043 20.079c4.746-.784 7.206-8.484 6.167-10.608-1.039-2.124-18.431-22.336-18.431-22.336l-11.779 12.865z"
      style={{
        fill: "#fff",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Defs>
      <Path
        id="i"
        d="M361.347 209.263c-1.882 6.552-2.665 13.6.468 19.083 1.566 4.699 10.694 9.185 13.827 13.101 9.831-2.398 13.995-14.882 13.995-14.882s-5.685-8.066-11.374-16.535c-5.689-8.469-17.086-11.652-17.086-11.652"
      />
    </Defs>
    <Use
      xlinkHref="#i"
      style={{
        overflow: "visible",
        fill: "#92e3a9",
      }}
    />
    <ClipPath id="j">
      <Use
        xlinkHref="#i"
        style={{
          overflow: "visible",
        }}
      />
    </ClipPath>
    <Path
      d="M361.347 209.263c-1.882 6.552-2.665 13.6.468 19.083 1.566 4.699 10.694 9.185 13.827 13.101 9.831-2.398 13.995-14.882 13.995-14.882s-5.685-8.066-11.374-16.535c-5.689-8.469-17.086-11.652-17.086-11.652"
      style={{
        opacity: 0.4,
        clipPath: "url(#j)",
        fill: "#fff",
      }}
    />
    <Use
      xlinkHref="#i"
      style={{
        overflow: "visible",
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M266.208 193.876s-3.198-7.523-5.21-9.862c-1.924-2.236-4.016-4.014-4.56-4.079-1.388-.167-5.913 2.348-5.913 2.348s-.007 1.414 2.446 1.209c0 0-3.539 2.204-3.71 3.937-.171 1.733 2.028 4.177 2.461 5.136.433.958 1.267.405 1.267.405s3.82 5.2 7.51 5.561"
      style={{
        fill: "#fff",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M252.989 192.969s.427-.41.497-1.415c0 0 1.534.005 1.612-1.341 0 0 2.252.137 1.311-2.723"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M255.099 190.214s-2.551-2.743-2.702-3.804M253.486 191.555s-2.798-2.502-2.645-3.639M256.034 181.804s-1.057 1.393-2.786 1.463"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M255.406 182.419s.726.552 1.302 1.256M362.314 207.19c.609-1.029 1.423-1.94 2.427-2.596"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M342.594 440.646h22.27l-.489 1.698h-21.762z"
      style={{
        fill: "#fff",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M349.742 434.064s7.309-2.419 10.837.605M349.742 432.464s6.427-2.034 10.837.684M350.12 430.889s6.049-2.281 10.459.844"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="m345.325 434.257.721.71a.6.6 0 0 1 .084.754c-.519.814-1.586 2.766-1.491 4.926h-1.071s.373-2.874 1.544-4.906l-.548-.553.761-.931z"
      style={{
        fill: "#263238",
      }}
    />
    <Path
      d="M370.787 252.2c-1.26 1.764-18.378 22.432-26.375 21.307M326.342 234.533s9.556 14.738 24.732 14.438"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <G
      style={{
        opacity: 0.2,
      }}
    >
      <Path
        d="M351.074 307.389c.871.198 17.684 6.33 21.047 12.462M355.161 316.29c-.842 1.187-7.983 23.539-11.039 29.67-3.056 6.132-13.342 33.626-15.715 35.209 0 0-3.165 16.022-5.143 18.593-1.978 2.572-12.211 23.061-12.211 23.061M299.579 422.824l12.41 2.653M350.12 424.151s9.934-.894 13.297 2.887M361.597 318.862c.831 4.747 13.293 31.055 16.26 28.088"
        style={{
          fill: "none",
          stroke: "#fff",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
        }}
      />
      <Path
        d="M356.769 321.236s-1.461-6.527-.077-9.495c.529-1.134-10.681 2.769-14.44 1.879M355.161 288.912s1.961 5.62 2.438 7.4c.478 1.78-3.574 8.703-2.438 10.088M382.407 283.653s2.769 4.24 2.769 7.362 3.758 4.506 3.758 4.506"
        style={{
          fill: "none",
          stroke: "#fff",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
        }}
      />
      <Path
        d="M388.311 279.018s3.392 12.745.623 28.371-7.319 47.473-9.099 55.582c-1.78 8.11-5.934 26.308-5.934 28.879 0 2.571-4.174 17.209-4.174 17.209M370.736 386.906c-.593 0-11.666 10.681-14.437 10.879M322.275 380.378c-.593.198-9.89 4.747-10.286 3.363"
        style={{
          fill: "none",
          stroke: "#fff",
          strokeLinecap: "round",
          strokeLinejoin: "round",
          strokeMiterlimit: 10,
        }}
      />
    </G>
    <Defs>
      <Path
        id="k"
        d="M368.852 297.827a4.452 4.452 0 0 1-4.796-4.08l-2.198-27.264c-.189-2.342 1.479-4.387 3.766-4.738l.855-2.859c.16-.536.633-.919 1.19-.963l11.323-.913a1.356 1.356 0 0 1 1.329.76l1.287 2.655 18.96-1.528a4.453 4.453 0 0 1 4.448 2.676"
      />
    </Defs>
    <Use
      xlinkHref="#k"
      style={{
        overflow: "visible",
        fill: "#92e3a9",
      }}
    />
    <ClipPath id="l">
      <Use
        xlinkHref="#k"
        style={{
          overflow: "visible",
        }}
      />
    </ClipPath>
    <Path
      d="M368.852 297.827a4.452 4.452 0 0 1-4.796-4.08l-2.198-27.264c-.189-2.342 1.479-4.387 3.766-4.738l.855-2.859c.16-.536.633-.919 1.19-.963l11.323-.913a1.356 1.356 0 0 1 1.329.76l1.287 2.655 18.96-1.528a4.453 4.453 0 0 1 4.448 2.676"
      style={{
        opacity: 0.3,
        clipPath: "url(#l)",
        fillOpacity: 0.7,
      }}
    />
    <Use
      xlinkHref="#k"
      style={{
        overflow: "visible",
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="m368.852 297.827 34.63-2.791c2.451-.198 4.594-2.365 4.787-4.84l1.907-24.493c.193-2.475-1.638-4.322-4.089-4.125l-34.63 2.791c-2.451.198-4.594 2.365-4.787 4.84l-1.907 24.493c-.193 2.476 1.638 4.323 4.089 4.125z"
      style={{
        fill: "#92e3a9",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M395.643 234.35c3.581 3.443 5.427 6.13 5.164 17.314-.264 11.184-2.284 27.355-3.606 38.284l-9.511 1.455s1.489-17.199 0-28.526c-1.624-12.355-.338-19.278 1.146-20.168"
      style={{
        fill: "#fff",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M388.231 281.873c-.198 3.204-6.049 4.184-6.484 6.198-.142.657-2.155 5.077-2.155 5.077s-.142 2.372.268 3.793c.497 1.719 2.051.716 2.051.716l.495 1.228c.791 1.978 2.276.147 4.794 1.385 2.517 1.237 3.738-.396 4.714 0 .976.396 4.141-.593 4.734-1.78s-.346-6.447.552-8.541c.899-2.094.923-6.315.923-8.075"
      style={{
        fill: "#fff",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M381.912 297.656s.043-3.223 2.077-4.509"
      style={{
        fill: "#fff",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M163.301 181.447h88.952v18.376h-88.952zM163.301 205.339h88.952v18.376h-88.952zM163.301 229.23h88.952v18.376h-88.952zM163.301 253.122h88.952v18.376h-88.952zM163.301 277.013h88.952v18.376h-88.952z"
      style={{
        fill: "#999",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M253.009 201.887h-71.274a1.983 1.983 0 0 1-1.983-1.983v-18.538c0-1.095.888-1.983 1.983-1.983h71.274c1.095 0 1.983.888 1.983 1.983v18.538a1.983 1.983 0 0 1-1.983 1.983zM253.009 225.779h-71.274a1.983 1.983 0 0 1-1.983-1.983v-18.538c0-1.095.888-1.983 1.983-1.983h71.274c1.095 0 1.983.888 1.983 1.983v18.538a1.983 1.983 0 0 1-1.983 1.983zM253.009 249.671h-71.274a1.983 1.983 0 0 1-1.983-1.983V229.15c0-1.095.888-1.983 1.983-1.983h71.274c1.095 0 1.983.888 1.983 1.983v18.538a1.983 1.983 0 0 1-1.983 1.983zM253.009 273.562h-71.274a1.983 1.983 0 0 1-1.983-1.983v-18.538c0-1.095.888-1.983 1.983-1.983h71.274c1.095 0 1.983.888 1.983 1.983v18.538a1.983 1.983 0 0 1-1.983 1.983zM253.009 297.454h-71.274a1.983 1.983 0 0 1-1.983-1.983v-18.538c0-1.095.888-1.983 1.983-1.983h71.274c1.095 0 1.983.888 1.983 1.983v18.538a1.983 1.983 0 0 1-1.983 1.983z"
      style={{
        fill: "#bfbfbf",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M262.083 194.16s-5.164-2.309-4.974-5.965c.062-1.188-.699-2.709-.699-2.709s-5.003-1.183-4.302-3.179l6.841.65s3.469 1.889 4.956 6.017"
      style={{
        fill: "#fff",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M68.496 178.945h104.876v263.399H68.496z"
      style={{
        fill: "#757575",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M137.134 201.892H65.86a1.983 1.983 0 0 1-1.983-1.983v-18.538c0-1.095.888-1.983 1.983-1.983h71.274c1.095 0 1.983.888 1.983 1.983v18.538a1.983 1.983 0 0 1-1.983 1.983z"
      style={{
        fill: "#999",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M176.009 201.892h-71.274a1.983 1.983 0 0 1-1.983-1.983v-18.538c0-1.095.888-1.983 1.983-1.983h71.274c1.095 0 1.983.888 1.983 1.983v18.538a1.983 1.983 0 0 1-1.983 1.983z"
      style={{
        fill: "#bfbfbf",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="m104.736 198.527.014-15.792a1.365 1.365 0 0 1 1.365-1.363l68.529.016c.754 0 1.365.612 1.364 1.366l-.014 15.776a1.364 1.364 0 0 1-1.365 1.363H106.1a1.364 1.364 0 0 1-1.364-1.366z"
      style={{
        fill: "#263238",
      }}
    />
    <Path
      d="M148.077 199.06h-37.582c-.728 0-1.319-.59-1.319-1.319v-3.824c0-.728.59-1.319 1.319-1.319h37.582c.728 0 1.319.59 1.319 1.319v3.824a1.32 1.32 0 0 1-1.319 1.319zM155.955 191.939h-37.582c-.728 0-1.319-.59-1.319-1.319v-3.824c0-.728.59-1.319 1.319-1.319h37.582c.728 0 1.319.59 1.319 1.319v3.824a1.32 1.32 0 0 1-1.319 1.319z"
      style={{
        fill: "#92e3a9",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M137.134 321.35H65.86a1.983 1.983 0 0 1-1.983-1.983v-18.538c0-1.095.888-1.983 1.983-1.983h71.274c1.095 0 1.983.888 1.983 1.983v18.538a1.983 1.983 0 0 1-1.983 1.983z"
      style={{
        fill: "#999",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M176.009 321.35h-71.274a1.983 1.983 0 0 1-1.983-1.983v-18.538c0-1.095.888-1.983 1.983-1.983h71.274c1.095 0 1.983.888 1.983 1.983v18.538a1.983 1.983 0 0 1-1.983 1.983z"
      style={{
        fill: "#bfbfbf",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M112.613 308.31h44.661v3.575h-44.661z"
      style={{
        fill: "#757575",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M165.106 310.098a1.805 1.805 0 1 1-3.61 0 1.805 1.805 0 0 1 3.61 0z"
      style={{
        fill: "#92e3a9",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M171.55 310.098a1.805 1.805 0 1 1-3.61 0 1.805 1.805 0 0 1 3.61 0z"
      style={{
        fill: "#757575",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M114.242 309.505h26.13v1.185h-26.13z"
      style={{
        fill: "#92e3a9",
      }}
    />
    <Path
      d="M137.134 345.241H65.86a1.983 1.983 0 0 1-1.983-1.983V324.72c0-1.095.888-1.983 1.983-1.983h71.274c1.095 0 1.983.888 1.983 1.983v18.538a1.983 1.983 0 0 1-1.983 1.983z"
      style={{
        fill: "#999",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M176.009 345.241h-71.274a1.983 1.983 0 0 1-1.983-1.983V324.72c0-1.095.888-1.983 1.983-1.983h71.274c1.095 0 1.983.888 1.983 1.983v18.538a1.983 1.983 0 0 1-1.983 1.983z"
      style={{
        fill: "#bfbfbf",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M112.613 332.202h44.661v3.575h-44.661z"
      style={{
        fill: "#757575",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Circle
      cx={163.301}
      cy={333.989}
      r={1.805}
      style={{
        fill: "#92e3a9",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M171.55 333.989a1.805 1.805 0 1 1-3.61 0 1.805 1.805 0 0 1 3.61 0z"
      style={{
        fill: "#757575",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M114.242 333.397h26.13v1.185h-26.13z"
      style={{
        fill: "#92e3a9",
      }}
    />
    <Path
      d="M137.134 369.133H65.86a1.983 1.983 0 0 1-1.983-1.983v-18.538c0-1.095.888-1.983 1.983-1.983h71.274c1.095 0 1.983.888 1.983 1.983v18.538a1.983 1.983 0 0 1-1.983 1.983z"
      style={{
        fill: "#999",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M176.009 369.133h-71.274a1.983 1.983 0 0 1-1.983-1.983v-18.538c0-1.095.888-1.983 1.983-1.983h71.274c1.095 0 1.983.888 1.983 1.983v18.538a1.983 1.983 0 0 1-1.983 1.983z"
      style={{
        fill: "#bfbfbf",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M112.613 356.093h44.661v3.575h-44.661z"
      style={{
        fill: "#757575",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Circle
      cx={163.301}
      cy={357.881}
      r={1.805}
      style={{
        fill: "#92e3a9",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M171.55 357.881a1.805 1.805 0 1 1-3.61 0 1.805 1.805 0 0 1 3.61 0z"
      style={{
        fill: "#757575",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M114.242 357.288h26.13v1.185h-26.13z"
      style={{
        fill: "#92e3a9",
      }}
    />
    <Path
      d="M137.134 393.025H65.86a1.983 1.983 0 0 1-1.983-1.983v-18.538c0-1.095.888-1.983 1.983-1.983h71.274c1.095 0 1.983.888 1.983 1.983v18.538a1.983 1.983 0 0 1-1.983 1.983z"
      style={{
        fill: "#999",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M176.009 393.025h-71.274a1.983 1.983 0 0 1-1.983-1.983v-18.538c0-1.095.888-1.983 1.983-1.983h71.274c1.095 0 1.983.888 1.983 1.983v18.538a1.983 1.983 0 0 1-1.983 1.983z"
      style={{
        fill: "#bfbfbf",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M112.613 379.985h44.661v3.575h-44.661z"
      style={{
        fill: "#757575",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M165.106 381.772a1.805 1.805 0 1 1-3.61 0 1.805 1.805 0 0 1 3.61 0z"
      style={{
        fill: "#92e3a9",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M171.55 381.772a1.805 1.805 0 1 1-3.61 0 1.805 1.805 0 0 1 3.61 0z"
      style={{
        fill: "#757575",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M114.242 381.18h26.13v1.185h-26.13z"
      style={{
        fill: "#92e3a9",
      }}
    />
    <Path
      d="M137.134 416.916H65.86a1.983 1.983 0 0 1-1.983-1.983v-18.538c0-1.095.888-1.983 1.983-1.983h71.274c1.095 0 1.983.888 1.983 1.983v18.538a1.983 1.983 0 0 1-1.983 1.983z"
      style={{
        fill: "#999",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M176.009 416.916h-71.274a1.983 1.983 0 0 1-1.983-1.983v-18.538c0-1.095.888-1.983 1.983-1.983h71.274c1.095 0 1.983.888 1.983 1.983v18.538a1.983 1.983 0 0 1-1.983 1.983z"
      style={{
        fill: "#bfbfbf",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M112.613 403.876h44.661v3.575h-44.661z"
      style={{
        fill: "#757575",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Circle
      cx={163.301}
      cy={405.664}
      r={1.805}
      style={{
        fill: "#92e3a9",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M171.55 405.664a1.805 1.805 0 1 1-3.61 0 1.805 1.805 0 0 1 3.61 0z"
      style={{
        fill: "#757575",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M114.242 405.071h26.13v1.185h-26.13z"
      style={{
        fill: "#92e3a9",
      }}
    />
    <Path
      d="M137.134 440.808H65.86a1.983 1.983 0 0 1-1.983-1.983v-18.538c0-1.095.888-1.983 1.983-1.983h71.274c1.095 0 1.983.888 1.983 1.983v18.538a1.983 1.983 0 0 1-1.983 1.983z"
      style={{
        fill: "#999",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M176.009 440.808h-71.274a1.983 1.983 0 0 1-1.983-1.983v-18.538c0-1.095.888-1.983 1.983-1.983h71.274c1.095 0 1.983.888 1.983 1.983v18.538a1.983 1.983 0 0 1-1.983 1.983z"
      style={{
        fill: "#bfbfbf",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M112.613 427.768h44.661v3.575h-44.661z"
      style={{
        fill: "#757575",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Circle
      cx={163.301}
      cy={429.555}
      r={1.805}
      style={{
        fill: "#92e3a9",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M171.55 429.555a1.805 1.805 0 1 1-3.61 0 1.805 1.805 0 0 1 3.61 0z"
      style={{
        fill: "#757575",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M114.242 428.963h26.13v1.185h-26.13z"
      style={{
        fill: "#92e3a9",
      }}
    />
    <Path
      d="M137.134 297.458H65.86a1.983 1.983 0 0 1-1.983-1.983v-18.538c0-1.095.888-1.983 1.983-1.983h71.274c1.095 0 1.983.888 1.983 1.983v18.538a1.983 1.983 0 0 1-1.983 1.983z"
      style={{
        fill: "#999",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M176.009 297.458h-71.274a1.983 1.983 0 0 1-1.983-1.983v-18.538c0-1.095.888-1.983 1.983-1.983h71.274c1.095 0 1.983.888 1.983 1.983v18.538a1.983 1.983 0 0 1-1.983 1.983z"
      style={{
        fill: "#bfbfbf",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="m104.736 294.093.014-15.792a1.365 1.365 0 0 1 1.365-1.363l68.529.016c.754 0 1.365.612 1.364 1.366l-.014 15.776a1.364 1.364 0 0 1-1.365 1.363H106.1a1.364 1.364 0 0 1-1.364-1.366z"
      style={{
        fill: "#263238",
      }}
    />
    <Path
      d="M148.077 294.626h-37.582c-.728 0-1.319-.59-1.319-1.319v-3.824c0-.728.59-1.319 1.319-1.319h37.582c.728 0 1.319.59 1.319 1.319v3.824a1.32 1.32 0 0 1-1.319 1.319zM170.287 294.626h-17.582c-.728 0-1.319-.59-1.319-1.319v-3.824c0-.728.59-1.319 1.319-1.319h17.582c.728 0 1.319.59 1.319 1.319v3.824a1.32 1.32 0 0 1-1.319 1.319zM132.408 288.109h-17.582c-.728 0-1.319-.59-1.319-1.319v-3.824c0-.728.59-1.319 1.319-1.319h17.582c.728 0 1.319.59 1.319 1.319v3.824a1.32 1.32 0 0 1-1.319 1.319z"
      style={{
        fill: "#92e3a9",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M137.134 273.567H65.86a1.983 1.983 0 0 1-1.983-1.983v-18.538c0-1.095.888-1.983 1.983-1.983h71.274c1.095 0 1.983.888 1.983 1.983v18.538a1.983 1.983 0 0 1-1.983 1.983z"
      style={{
        fill: "#999",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M176.009 273.567h-71.274a1.983 1.983 0 0 1-1.983-1.983v-18.538c0-1.095.888-1.983 1.983-1.983h71.274c1.095 0 1.983.888 1.983 1.983v18.538a1.983 1.983 0 0 1-1.983 1.983z"
      style={{
        fill: "#bfbfbf",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="m104.736 270.201.014-15.792a1.365 1.365 0 0 1 1.365-1.363l68.529.016c.754 0 1.365.612 1.364 1.366l-.014 15.776a1.364 1.364 0 0 1-1.365 1.363H106.1a1.364 1.364 0 0 1-1.364-1.366z"
      style={{
        fill: "#263238",
      }}
    />
    <Path
      d="M148.687 270.734h-17.582c-.728 0-1.319-.59-1.319-1.319v-3.824c0-.728.59-1.319 1.319-1.319h17.582c.728 0 1.319.59 1.319 1.319v3.824c0 .729-.59 1.319-1.319 1.319zM170.287 270.734h-17.582c-.728 0-1.319-.59-1.319-1.319v-3.824c0-.728.59-1.319 1.319-1.319h17.582c.728 0 1.319.59 1.319 1.319v3.824a1.32 1.32 0 0 1-1.319 1.319zM127.467 270.734h-17.583c-.728 0-1.319-.59-1.319-1.319v-3.824c0-.728.59-1.319 1.319-1.319h17.583c.728 0 1.319.59 1.319 1.319v3.824a1.32 1.32 0 0 1-1.319 1.319zM143.291 263.811h-17.582c-.728 0-1.319-.59-1.319-1.319v-3.824c0-.728.59-1.319 1.319-1.319h17.582c.728 0 1.319.59 1.319 1.319v3.824a1.32 1.32 0 0 1-1.319 1.319z"
      style={{
        fill: "#92e3a9",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M137.134 249.675H65.86a1.983 1.983 0 0 1-1.983-1.983v-18.538c0-1.095.888-1.983 1.983-1.983h71.274c1.095 0 1.983.888 1.983 1.983v18.538a1.983 1.983 0 0 1-1.983 1.983z"
      style={{
        fill: "#999",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M176.009 249.675h-71.274a1.983 1.983 0 0 1-1.983-1.983v-18.538c0-1.095.888-1.983 1.983-1.983h71.274c1.095 0 1.983.888 1.983 1.983v18.538a1.983 1.983 0 0 1-1.983 1.983z"
      style={{
        fill: "#bfbfbf",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="m104.736 246.31.014-15.792a1.365 1.365 0 0 1 1.365-1.363l68.529.016c.754 0 1.365.612 1.364 1.366l-.014 15.776a1.364 1.364 0 0 1-1.365 1.363H106.1a1.364 1.364 0 0 1-1.364-1.366z"
      style={{
        fill: "#263238",
      }}
    />
    <Path
      d="M161.077 246.843h-37.582c-.728 0-1.319-.59-1.319-1.319V241.7c0-.728.59-1.319 1.319-1.319h37.582c.728 0 1.319.59 1.319 1.319v3.824a1.32 1.32 0 0 1-1.319 1.319zM155.955 239.722h-37.582c-.728 0-1.319-.59-1.319-1.319v.176c0-.728.59-1.319 1.319-1.319h37.582c.728 0 1.319.59 1.319 1.319v-.176c0 .729-.591 1.319-1.319 1.319z"
      style={{
        fill: "#92e3a9",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M137.134 225.784H65.86a1.983 1.983 0 0 1-1.983-1.983v-18.538c0-1.095.888-1.983 1.983-1.983h71.274c1.095 0 1.983.888 1.983 1.983v18.538a1.983 1.983 0 0 1-1.983 1.983z"
      style={{
        fill: "#999",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M176.009 225.784h-71.274a1.983 1.983 0 0 1-1.983-1.983v-18.538c0-1.095.888-1.983 1.983-1.983h71.274c1.095 0 1.983.888 1.983 1.983v18.538a1.983 1.983 0 0 1-1.983 1.983z"
      style={{
        fill: "#bfbfbf",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="m104.736 222.418.014-15.792a1.365 1.365 0 0 1 1.365-1.363l68.529.016c.754 0 1.365.612 1.364 1.366l-.014 15.776a1.364 1.364 0 0 1-1.365 1.363H106.1a1.364 1.364 0 0 1-1.364-1.366z"
      style={{
        fill: "#263238",
      }}
    />
    <Path
      d="M168.077 222.951h-37.582c-.728 0-1.319-.59-1.319-1.319v-3.824c0-.728.59-1.319 1.319-1.319h37.582c.728 0 1.319.59 1.319 1.319v3.824c0 .729-.591 1.319-1.319 1.319zM155.955 215.83h-17.582c-.728 0-1.319-.59-1.319-1.319v-3.824c0-.728.59-1.319 1.319-1.319h17.582c.728 0 1.319.59 1.319 1.319v3.824c0 .729-.591 1.319-1.319 1.319z"
      style={{
        fill: "#92e3a9",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M68.165 193.653v5.407M69.879 193.653v5.407M71.593 193.653v5.407M73.308 193.653v5.407M75.022 193.653v5.407M68.165 432.653v5.407M69.879 432.653v5.407M71.593 432.653v5.407M73.308 432.653v5.407M75.022 432.653v5.407M68.165 408.753v5.407M69.879 408.753v5.407M71.593 408.753v5.407M73.308 408.753v5.407M75.022 408.753v5.407M68.165 384.853v5.407M69.879 384.853v5.407M71.593 384.853v5.407M73.308 384.853v5.407M75.022 384.853v5.407M68.165 360.953v5.407M69.879 360.953v5.407M71.593 360.953v5.407M73.308 360.953v5.407M75.022 360.953v5.407M68.165 337.053v5.407M69.879 337.053v5.407M71.593 337.053v5.407M73.308 337.053v5.407M75.022 337.053v5.407M68.165 313.153v5.407M69.879 313.153v5.407M71.593 313.153v5.407M73.308 313.153v5.407M75.022 313.153v5.407M68.165 289.253v5.407M69.879 289.253v5.407M71.593 289.253v5.407M73.308 289.253v5.407M75.022 289.253v5.407M68.165 265.353v5.407M69.879 265.353v5.407M71.593 265.353v5.407M73.308 265.353v5.407M75.022 265.353v5.407M68.165 241.453v5.407M69.879 241.453v5.407M71.593 241.453v5.407M73.308 241.453v5.407M75.022 241.453v5.407M68.165 217.553v5.407M69.879 217.553v5.407M71.593 217.553v5.407M73.308 217.553v5.407M75.022 217.553v5.407"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
  </Svg>
)
export default SectionThree
