import React, { useRef } from 'react';
import { View, Text, StyleSheet, ScrollView, TouchableOpacity } from 'react-native';
import ContactForm from './ContactForm';
import OurProjects from './OurProjects';
import OurServices from './OurServices';
import Business from '../assets/images/svg/Business';
import HeaderView from './header-view';
import { useSelector } from 'react-redux';

const LandingPage = () => {
	const uiTexts = require('../business-logic/ui-texts-english.json');
	const { layout } = useSelector(state => state.layoutSlice);

	const scrollViewRef = useRef(null);

	const scrollToServices = () => {
		scrollViewRef.current.scrollTo({ y: 550, animated: true });
	};

	const scrollToProjects = () => {
		scrollViewRef.current.scrollTo({ y: 800, animated: true });
	};

	const scrollToContact = () => {
		scrollViewRef.current.scrollToEnd({ animated: true });
	};

	const centerBlock = layout === 'portrait' ? styles.centerTitle : {};
	const contactContainerWidth = layout === 'portrait' ? styles.contactContainerWidthPortrait : styles.contactContainerWidthNonPortrait;

	return (
		<>
			<HeaderView
				scrollToContact={scrollToContact}
				scrollToProjects={scrollToProjects}
				scrollToServices={scrollToServices}
			/>
			<ScrollView ref={scrollViewRef} style={styles.flex} showsVerticalScrollIndicator={false}>
				<View style={styles.container}>
					<View style={styles.innerContainer}>
						<Text style={[centerBlock, styles.Title]}>{uiTexts.Company.Title}</Text>
						<Text style={[styles.Content]}>{uiTexts.Company.Content}</Text>
						<TouchableOpacity style={[centerBlock, styles.button]} onPress={() => {
							scrollViewRef.current.scrollToEnd({ animated: true }); // Scroll to the end (form section)
						}}>
							<Text style={styles.buttonText}>{uiTexts.Company.ContactMe}</Text>
						</TouchableOpacity>
					</View>
					<View style={layout === 'portrait' ? null : styles.flex}>
						<Business />
					</View>
				</View>
				{/**Services */}
				<View>
					<OurServices />
				</View>
				{/**Projects */}
				<View style={{ marginTop: 20 }}>
					<OurProjects />
				</View>
				<View style={[contactContainerWidth, styles.contactContainer]}>
					<ContactForm />
				</View>
			</ScrollView>

		</>

	);
};

export default LandingPage;

const styles = StyleSheet.create({
	flex: {
		flex: 1,
	},
	container: {
		flexDirection: 'row',
		justifyContent: 'space-around',
	},
	innerContainer: {
		flexDirection: 'column',
		flex: 1
	},
	centerTitle: {
		alignSelf: 'center'
	},
	Title: {
		fontSize: 36,
		fontWeight: 'bold',
		color: '#19B892'
	},
	Content: {
		textAlign: 'justify',
		marginTop: 20,
		lineHeight: 30,
		fontSize: 14,
		fontWeight: '500',
		marginRight: 20
	},
	contactContainerWidthNonPortrait: {
		width: '70%',
	},
	contactContainerWidthPortrait: {
		width: '100%',
	},
	contactContainer: {
		alignSelf: 'center',
		marginTop: 20,
		marginBottom: 50
	},
	button: {
		width: '70%',
		height: 45,
		backgroundColor: '#19B892',
		borderRadius: 8,
		justifyContent: 'center',
		alignItems: 'center',
		marginVertical: 20
	},
	buttonText: {
		fontSize: 12,
		fontWeight: '500',
		color: 'white',
		textAlign: 'center'
	}
});
