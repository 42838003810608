import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { useSelector } from 'react-redux';
const OurProjects = () => {
	const { layout } = useSelector(state => state.layoutSlice);
   const uiTexts = require('../business-logic/ui-texts-english.json');
	const projectWidth = layout === 'portrait' ? styles.projectWidthPortrait : styles.projectWidthNotPortrait;
   return (
      <View style={styles.container}>
         <Text style={styles.title}>{uiTexts.Projects.Title}</Text>
         <View style={styles.projectsContainer}>
            <View style={[projectWidth, styles.project]}>
               <View style={styles.projectContent}>
                  <Text style={styles.projectTitle}>{uiTexts.Projects.ECommerce}</Text>
                  <Text style={styles.projectDescription}>{uiTexts.Projects.ECommerceContent}</Text>
               </View>
            </View>
            <View style={[projectWidth, styles.project,{backgroundColor:'grey'}]}>
               <View style={styles.projectContent}>
                  <Text style={styles.projectTitle}>{uiTexts.Projects.SocialMedia}</Text>
                  <Text style={styles.projectDescription}>{uiTexts.Projects.SocialMediaContent}</Text>
               </View>
            </View>
            <View style={[projectWidth, styles.project,{backgroundColor:'#FFBC7E'}]}>
               <View style={[styles.projectContent]}>
                  <Text style={styles.projectTitle}>{uiTexts.Projects.Game}</Text>
                  <Text style={styles.projectDescription}>{uiTexts.Projects.GameContent}</Text>
               </View>
            </View>
         </View>
      </View>
   )
}

const styles = StyleSheet.create({
   container: {
      flex: 1,
   },
   title: {
      fontSize: 36,
      fontWeight: 'bold',
      color: '#19B892',
      marginTop: 20,
      textAlign: 'center',
   },
   projectsContainer: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-evenly',
      marginTop: 20, 
   },
	projectWidthPortrait: {
		width: '100%',
	},		
	projectWidthNotPortrait: {
		width: '48%',
	},		
   project: {
      borderRadius: 25,
      backgroundColor: '#19B892',
      marginBottom: 20,
      justifyContent: 'center',
      alignItems:'center'
   },
   projectContent: {
      alignItems: 'center',
      padding: 20,
   },
   projectTitle: {
      fontWeight: 'bold',
      fontSize: 23,
      marginTop: 10,
      textAlign: 'center',
   },
   projectDescription: {
      fontSize: 12,
      fontWeight: '500',
      textAlign: 'center',
      marginTop: 20,
   },
});


export default OurProjects;
