import React, { useState } from 'react';
import { View, Text, TextInput, StyleSheet, TouchableOpacity } from 'react-native';
import { Formik } from 'formik';
import { Linking } from 'react-native';

import config from '../business-logic/config';

const ContactForm = () => {
	const uiTexts = require('../business-logic/ui-texts-english.json');

	const initialValues = { name: '', email: '', subject: '', message: '' };
	const [formData, setFormData] = useState(initialValues);

	//Form mail işlemleri
	const handleFormSubmit = async (values, { resetForm }) => {
		const emailSubject = values.subject;
		const emailBody = `\n${values.message}\n\n${values.name}\n${values.email}}`;
		const emailUrl = `mailto:corporate-contact@eseme.one?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`;
		Linking.openURL(emailUrl)
			.catch(error => {
				console.error('Error opening email client:', error);
			});

		// Formu sıfırlama
		resetForm();
		setFormData(initialValues);
	};

	return (
		<Formik
			initialValues={formData}
			onSubmit={handleFormSubmit}
		>
			{({ handleChange, handleSubmit, values }) => (
				<View>
					<Text style={{ fontSize: 36, fontWeight: 'bold', color: '#19B892', textAlign: 'center', marginTop: 20 }}>{uiTexts.Contact.Title}</Text>
					<View style={{ marginTop: 20 }}>
						<TextInput
							style={styles.input}
							placeholder={uiTexts.Contact.Name}
							onChangeText={handleChange('name')}
							value={values.name}
						/>

						<TextInput
							style={styles.input}
							placeholder={uiTexts.Contact.Email}
							onChangeText={handleChange('email')}
							value={values.email}
						/>

						<TextInput
							style={styles.input}
							placeholder={uiTexts.Contact.Subject}
							onChangeText={handleChange('subject')}
							value={values.subject}
						/>

						<TextInput
							style={styles.inputMessage}
							placeholder={uiTexts.Contact.Message}
							onChangeText={handleChange('message')}
							value={values.message}
							multiline={true}
						/>
					</View>
					<TouchableOpacity style={styles.button} onPress={handleSubmit}>
						<Text style={styles.buttonText}>{uiTexts.Contact.Send}</Text>
					</TouchableOpacity>
					<Text style={styles.emailText} >
							{`${uiTexts.Contact.EmailAddress}\n`}
							<Text style={styles.emailText2} onPress={() => Linking.openURL(`mailto:${config.emailAddress}`)}>
								{`${config.emailAddress}`}
							</Text>
						</Text>
					<View style={{ alignSelf: 'center', marginTop: 20 }}>
						<Text>{uiTexts.Contact.SecurityText.replace("{year}", new Date().getFullYear())}</Text>
					</View>
				</View>
			)}
		</Formik>
	);
};

export default ContactForm;
const styles = StyleSheet.create({
	input: {
		height: 45,
		backgroundColor: '#E8E6E6',
		paddingLeft: 10,
		fontSize: 14,
		fontWeight: 'bold',
		color: '#A9A6A6',
		borderRadius: 5,
		marginBottom: 10,
	},
	inputMessage: {
		height: 65,
		backgroundColor: '#E8E6E6',
		paddingLeft: 10,
		fontSize: 14,
		fontWeight: 'bold',
		color: '#A9A6A6',
		borderRadius: 5,
		marginBottom: 10,
	},
	button: {
		width: '100%',
		height: 45,
		backgroundColor: '#19B892',
		borderRadius: 8,
		alignItems: 'center',
		justifyContent: 'center',
	},
	buttonText: {
		textAlign: 'center',
		color: 'white',
		fontSize: 20,
	},
	emailText: {
		fontSize: 16,
		marginVertical: 20,
		alignSelf: 'center',
		textAlign: 'center'
	},
	emailText2: {
		textDecorationLine: 'underline',
		fontColor: '#0000EE',
		fontSize: 14,
		marginVertical: 20,
		alignSelf: 'center',
		textAlign: 'center'
	}
});

