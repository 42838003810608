import * as React from "react"
import Svg, { Path, Circle } from "react-native-svg"
const Business = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      enableBackground: "new 0 0 500 500",
    }}
    viewBox="0 0 500 500"
    {...props}
  >
    <Path
      d="M458.969 131.83C432.426 81.127 382.042 46 327.71 32.689 275.88 19.991 213.483 20.616 174.469 62.43c-25.689 27.532-35.976 65.863-58.648 95.593-11.269 14.777-25.372 21.537-40.137 31.88-35.985 25.21-62.451 65.776-56.651 111.658 9.697 76.712 79.673 117.911 150.001 122.538 92.938 6.115 159.219-6.313 215.522-42.293 29.242-18.687 58.406-40.862 76.257-71.554 26.996-46.415 25.887-104.947 8.424-154.785a177.737 177.737 0 0 0-10.268-23.637z"
      style={{
        fill: "#92e3a9",
      }}
    />
    <Path
      d="M458.969 131.83C432.426 81.127 382.042 46 327.71 32.689 275.88 19.991 213.483 20.616 174.469 62.43c-25.689 27.532-35.976 65.863-58.648 95.593-11.269 14.777-25.372 21.537-40.137 31.88-35.985 25.21-62.451 65.776-56.651 111.658 9.697 76.712 79.673 117.911 150.001 122.538 92.938 6.115 159.219-6.313 215.522-42.293 29.242-18.687 58.406-40.862 76.257-71.554 26.996-46.415 25.887-104.947 8.424-154.785a177.737 177.737 0 0 0-10.268-23.637z"
      style={{
        opacity: 0.8,
        fill: "#fff",
      }}
    />
    <Path
      d="M246.097 468.8h-9.546V53.341a4.773 4.773 0 0 1 4.773-4.773h0a4.773 4.773 0 0 1 4.773 4.773V468.8z"
      style={{
        fill: "#92e3a9",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M152.592 103.907h83.69v29.468h-83.69zM246.259 72.32h83.69v29.468h-83.69zM246.259 153.527l46.732 5.937V118.12l-46.732 5.938z"
      style={{
        fill: "#263238",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="m283.573 139.583-10.314-11.374v4.96l-21.541.847v10.804l21.541 1.07v4.96zM317.19 84.914l-15.904-9.182v4.023h-41.928v10.319h41.928v4.023zM166.01 118.859l15.904-9.182v4.023h41.928v10.319h-41.928v4.022z"
      style={{
        fill: "#92e3a9",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Circle
      cx={371.343}
      cy={98.804}
      r={34.677}
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M394.147 121.7a32.41 32.41 0 0 1-7.896 5.782M381.629 129.448a32.282 32.282 0 0 1-10.286 1.671c-17.847 0-32.315-14.468-32.315-32.315s14.468-32.315 32.315-32.315c17.847 0 32.315 14.468 32.315 32.315a32.165 32.165 0 0 1-4.536 16.52"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Circle
      cx={371.343}
      cy={98.804}
      r={2.559}
      style={{
        fill: "#263238",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="m375.024 113.173-3.681-14.369 21.477-14.711M143.448 188.775v51.239h-38.16M73.984 231.968v-57.165h69.464v9.459M99.808 240.014H73.984v-4.177"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M78.207 190.854h6.78v6.78h-6.78zM87.247 190.854h6.78v6.78h-6.78zM96.286 190.854h6.78v6.78h-6.78zM105.326 190.854h6.78v6.78h-6.78zM114.366 190.854h6.78v6.78h-6.78zM123.406 190.854h6.78v6.78h-6.78zM132.446 190.854h6.78v6.78h-6.78zM78.207 201.554h6.78v6.78h-6.78zM87.247 201.554h6.78v6.78h-6.78zM96.286 201.554h6.78v6.78h-6.78zM105.326 201.554h6.78v6.78h-6.78zM114.366 201.554h6.78v6.78h-6.78zM123.406 201.554h6.78v6.78h-6.78zM132.446 201.554h6.78v6.78h-6.78zM78.207 212.254h6.78v6.78h-6.78zM87.247 212.254h6.78v6.78h-6.78zM96.286 212.254h6.78v6.78h-6.78zM105.326 212.254h6.78v6.78h-6.78zM114.366 212.254h6.78v6.78h-6.78zM123.406 212.254h6.78v6.78h-6.78zM132.446 212.254h6.78v6.78h-6.78zM78.207 222.953h6.78v6.78h-6.78zM87.247 222.953h6.78v6.78h-6.78zM96.286 222.953h6.78v6.78h-6.78zM105.326 222.953h6.78v6.78h-6.78zM114.366 222.953h6.78v6.78h-6.78zM123.406 222.953h6.78v6.78h-6.78zM132.446 222.953h6.78v6.78h-6.78z"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M86.272 179.406H83.28v-10.973h2.993v10.973zm11.971-10.973H95.25v10.973h2.993v-10.973zm11.97 0h-2.993v10.973h2.993v-10.973zm11.97 0h-2.993v10.973h2.993v-10.973zm11.97 0h-2.992v10.973h2.992v-10.973z"
      style={{
        fill: "#263238",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M108.716 283.85c-14.633 0-26.495 11.862-26.495 26.495 0 14.633 11.862 26.495 26.495 26.495 14.633 0 26.495-11.862 26.495-26.495 0-14.633-11.862-26.495-26.495-26.495zm0 43.257c-9.257 0-16.762-7.505-16.762-16.762 0-9.258 7.505-16.762 16.762-16.762s16.762 7.505 16.762 16.762c.001 9.258-7.504 16.762-16.762 16.762z"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M109.257 283.864v9.746c9.005.288 16.221 7.66 16.221 16.735 0 4.589-1.848 8.744-4.835 11.771l7.644 6.087a26.398 26.398 0 0 0 6.924-17.858c0-14.451-11.572-26.192-25.954-26.481zM98.983 309.264h18.926M98.983 314.13h18.926M349.069 191.409h51.548M328.525 191.409h5.308M366.235 199.587h34.382M328.525 199.587h5.727M382.773 207.766h17.844M328.525 207.766h5.308M397.427 215.945h3.19M328.525 215.945h5.308M397.427 225.462h3.19M328.525 225.462h5.308M397.427 234.98h3.19M328.525 234.98h5.308M335.188 185.747h11.953v50.104h-11.953zM351.545 195.184h11.953v40.668h-11.953zM367.902 203.991h11.953v31.86h-11.953zM384.259 215.316h11.954v20.535h-11.954zM126.259 73.372a29.228 29.228 0 0 0-17.543-5.815c-16.215 0-29.36 13.145-29.36 29.36s13.145 29.36 29.36 29.36 29.36-13.145 29.36-29.36a29.262 29.262 0 0 0-7.871-20.007"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M94.885 110.368a19.238 19.238 0 0 0 13.831 5.842c10.656 0 19.294-8.638 19.294-19.294s-8.638-19.294-19.294-19.294-19.294 8.638-19.294 19.294c0 3.924 1.171 7.574 3.183 10.619"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Circle
      cx={108.716}
      cy={96.916}
      r={10.066}
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M364.887 297.183c3.232.456 4.716 2.967 4.716 6.542v.723h-2.776v-.913c0-2.359-.951-3.804-3.081-3.804-2.091 0-3.042 1.445-3.042 3.804 0 5.971 8.938 6.009 8.938 13.501 0 3.576-1.522 6.124-4.754 6.58v2.016h-2.434v-2.016c-3.309-.456-4.793-2.967-4.793-6.58v-1.635h2.777v1.826c0 2.396.989 3.765 3.118 3.765 2.168 0 3.119-1.369 3.119-3.765 0-5.934-8.899-6.01-8.899-13.502 0-3.651 1.483-6.047 4.678-6.542v-2.13h2.434v2.13z"
      style={{
        fill: "#263238",
      }}
    />
    <Path
      d="M373.381 332.863a24.497 24.497 0 0 0 5.249-3.098M354.975 287.395c-9.264 3.504-15.851 12.457-15.851 22.948 0 13.546 10.981 24.526 24.526 24.526 1.702 0 3.364-.173 4.969-.504M381.717 326.93a24.438 24.438 0 0 0 6.46-16.587c0-13.546-10.981-24.526-24.526-24.526-2.077 0-4.094.258-6.02.744"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Circle
      cx={363.65}
      cy={310.343}
      r={20.109}
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
      transform="rotate(-42.811 363.625 310.325)"
    />
    <Path
      d="M265.386 229.816s.245-.028.688-.082c2.898-.358 14.264-1.881 20.772-4.454 7.502-2.966 24.6-1.396 24.6-1.396l7.502 5.409-8.549 5.583s-12.038-4.013-20.412-3.664c-8.374.349-12.387 2.791-17.621 2.442-5.234-.349-11.689-3.838-11.689-3.838h4.709z"
      style={{
        fill: "#263238",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M233.329 212.84v11.227h18.5v-13zM228.276 201.943s-4.383-2.922-2.922 2.192c1.461 5.114 2.922 7.062 5.113 6.818 2.192-.243 3.409-.974 2.679-2.922-.73-1.948-4.87-6.088-4.87-6.088zM257.552 201.943s4.383-2.922 2.922 2.192c-1.461 5.114-2.922 7.062-5.114 6.818-2.192-.243-3.409-.974-2.679-2.922.732-1.948 4.871-6.088 4.871-6.088z"
      style={{
        fill: "#fff",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M237.773 178.567s-7.305 2.435-9.74 9.253c-2.435 6.818-.243 15.097.974 18.506 1.217 3.409 7.062 9.984 7.062 9.984h13.149s4.87-4.627 7.549-10.714c2.679-6.088 2.679-14.61 2.679-14.61s2.192.73 2.192-2.192-6.818-8.523-6.818-8.523 3.166-1.217.974-2.922-9.984-.974-9.984-.974-.976-5.113-8.037 2.192z"
      style={{
        fill: "#263238",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="m266.579 458.318 2 1.25s2.25 0 3.5 1.25.785 7.461.785 7.461-.997.737-5.747.737-15.211 1.161-16.211-.589.173-9.108.173-9.108l15.5-1.001z"
      style={{
        fill: "#858585",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M211.829 301.067s-1.5 11-1.5 13.25-.5 21.75-.5 21.75.25 51.25.25 52.25-4.25 15-2.75 24.5 4.5 46 4.5 46-1 8-.25 8.75 10 1.75 12 .75.75-10.75.75-10.75 1.25-4.25 1.25-7.25v-8c0-2.5 1.25-24.75 2.5-33.5s2-20.5 2-20.5l5.25-54 4-.25s5 58 5.25 62.25-.75 12.75 1.5 22.5 3.5 36.25 3.5 36.25-.5 2.5 0 3.5 1.75 4 1.75 4 2 .25 5.251 0c2.739-.211 4.392-.493 8.041-2.19 1.782-1.644 2.566-3.895 1.62-6.206-1.497-3.659-2.162-4.354-2.162-4.354s2.25-31.5 2.25-39.25-1.25-17.25-1.25-18 1.5-57 1.5-62.75-.5-21.25-3-28-42.5-5-46.5-4.75-5.25 4-5.25 4z"
      style={{
        fill: "#263238",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="m226.579 333.939 21.5-1"
      style={{
        fill: "none",
        stroke: "#757575",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="m239.079 223.567-13.5 3.25s-19.5 9.5-23.75 12-21 36.25-20.25 38 4 4 7.25 4.5 19.75-18.25 19.75-18.25 4.75 23.5 4.75 24.5-2.75 13-1.5 15 49.25 0 49.25 0 3.5.25 4-3-1-12.75-1-12.75l5.5-28.75s10.506 15.935 12.861 18.5c4.094 4.458 9.175 2.59 11.889-.75 2.364-2.911.25-8.5-.5-10.25s-14-31.5-18-32.75-20.75-7.25-20.75-7.25l-16-2z"
      style={{
        fill: "#fff",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M225.579 226.817s16.523-.341 19.273-.341 12.789-.086 12.789-.086l-2.539-5.414s-5.25-1.5-13.25-1.5-14 2.5-14 2.5l-2.273 4.841z"
      style={{
        fill: "#fff",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="m203.495 249.885 5.084 13.182-8.75-8.75M201.829 245.567l.607 1.574M229.964 285.388a25.058 25.058 0 0 1-2.635 1.93M252.829 247.567s-9.142 24.987-21.382 36.505M275.329 243.817l-5.75 14.25 9.25-10"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M179.329 283.067s1.75 5.75 7.25 10 20.25 18.25 20.25 18.25-.25 1 0 2.25 4.25 4.5 4.25 4.5v-2.5l1-2 1-2.5s2.5-1 3.25-1.5 1.25-1.25 1.25-1.25 2.75.5 4.5.5 4.25-1.75 2.75-2.25-3 0-5-1-3-2-5-2h-5.25l-13.75-22.5-16.5 2z"
      style={{
        fill: "#fff",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M185.246 267.701s-2.916.867-3.666 1.617-4.5 6.5-3.75 9.5 1.75 7.25 8.25 8.5 10-1.5 10.5-2.25 1.732-9.595.732-9.595-3.732 3.345-3.732 3.345-7.447-1.686-8.947-2.936.613-8.181.613-8.181zM299.941 283.067s-1.75 5.75-7.25 10-20.25 18.25-20.25 18.25.25 1 0 2.25-5.757 5.096-5.757 5.096v-3.644l-.384-2.301-.109-1.65s-2.5-1-3.25-1.5-1.25-1.25-1.25-1.25-2.75.5-4.5.5-4.25-1.75-2.75-2.25 3 0 5-1 3-2 5-2h5.25l13.75-22.5 16.5 1.999z"
      style={{
        fill: "#fff",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M295.066 268.928s1.875-.36 2.625.39 4.5 6.5 3.75 9.5-1.75 7.25-8.25 8.5-10-1.5-10.5-2.25-.904-9.257-.25-8.5c1.173 1.359 3.25 2.25 3.25 2.25s7.751-.395 8.966-4.777c.522-1.882.409-5.113.409-5.113z"
      style={{
        fill: "#fff",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M223.579 468.318c1.579-.789 1.133-7.028.874-9.622-1.289.604-3.263 1.344-5.624 1.622-4.25.5-7-1.5-7-1.5s-.803 8.646-.124 9.461c.738.884 9.874 1.039 11.874.039z"
      style={{
        fill: "#858585",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M400.988 469.329a.397.397 0 0 1-.397-.397v-51.583a.397.397 0 1 1 .794 0v51.583a.397.397 0 0 1-.397.397z"
      style={{
        fill: "#263238",
      }}
    />
    <Path
      d="M400.6 464.278s-2.715-3.878-6.206-5.818c-3.49-1.939-9.308-1.939-9.308-1.939s3.103 11.247 15.514 7.757zM400.988 464.278s2.715-3.878 6.206-5.818c3.491-1.939 9.308-1.939 9.308-1.939s-3.103 11.247-15.514 7.757zM400.6 450.315s-2.715-3.878-6.206-5.818c-3.49-1.939-9.308-1.939-9.308-1.939s3.103 11.248 15.514 7.757zM400.988 450.315s2.715-3.878 6.206-5.818c3.491-1.939 9.308-1.939 9.308-1.939s-3.103 11.248-15.514 7.757zM400.6 436.353s-2.715-3.878-6.206-5.818c-3.49-1.939-9.308-1.939-9.308-1.939s3.103 11.248 15.514 7.757zM400.988 436.353s2.715-3.878 6.206-5.818c3.491-1.939 9.308-1.939 9.308-1.939s-3.103 11.248-15.514 7.757zM400.6 422.391s-2.715-3.878-6.206-5.818c-3.49-1.939-9.308-1.939-9.308-1.939s3.103 11.247 15.514 7.757zM400.988 422.391s2.715-3.878 6.206-5.818c3.491-1.939 9.308-1.939 9.308-1.939s-3.103 11.247-15.514 7.757zM400.988 417.349s-3.104-4.176-3.261-8.166c-.156-3.99 2.467-9.182 2.467-9.182s9.507 7.845.794 17.348zM59.189 469.329a.429.429 0 0 1-.429-.429v-55.759a.429.429 0 1 1 .858 0V468.9a.429.429 0 0 1-.429.429z"
      style={{
        fill: "#263238",
      }}
    />
    <Path
      d="M58.77 463.869s-2.935-4.193-6.708-6.289S42 455.484 42 455.484s3.354 12.158 16.77 8.385zM59.189 463.869s2.935-4.193 6.708-6.289 10.062-2.096 10.062-2.096-3.354 12.158-16.77 8.385zM58.77 448.776s-2.935-4.192-6.708-6.289C48.289 440.391 42 440.391 42 440.391s3.354 12.158 16.77 8.385zM59.189 448.776s2.935-4.192 6.708-6.289c3.773-2.096 10.062-2.096 10.062-2.096s-3.354 12.158-16.77 8.385zM58.77 433.683s-2.935-4.192-6.708-6.289C48.289 425.298 42 425.298 42 425.298s3.354 12.159 16.77 8.385zM59.189 433.683s2.935-4.192 6.708-6.289c3.773-2.096 10.062-2.096 10.062-2.096s-3.354 12.159-16.77 8.385zM58.77 418.591s-2.935-4.192-6.708-6.289C48.289 410.206 42 410.206 42 410.206s3.354 12.158 16.77 8.385zM59.189 418.591s2.935-4.192 6.708-6.289c3.773-2.096 10.062-2.096 10.062-2.096s-3.354 12.158-16.77 8.385zM59.189 413.141s-3.355-4.514-3.525-8.827c-.169-4.313 2.667-9.926 2.667-9.926s10.276 8.48.858 18.753zM95.219 469.083V443.72a.429.429 0 1 0-.858 0v25.364h.858z"
      style={{
        fill: "#263238",
      }}
    />
    <Path
      d="M94.371 464.262s-2.935-4.192-6.708-6.289c-3.773-2.096-10.062-2.096-10.062-2.096s3.354 12.158 16.77 8.385zM94.79 464.262s2.935-4.192 6.708-6.289c3.773-2.096 10.062-2.096 10.062-2.096s-3.354 12.158-16.77 8.385zM94.371 449.17s-2.935-4.192-6.708-6.289c-3.773-2.096-10.062-2.096-10.062-2.096s3.354 12.158 16.77 8.385zM94.79 449.17s2.935-4.192 6.708-6.289c3.773-2.096 10.062-2.096 10.062-2.096s-3.354 12.158-16.77 8.385zM94.79 443.72s-3.355-4.514-3.525-8.827c-.169-4.313 2.667-9.926 2.667-9.926s10.276 8.48.858 18.753zM366.927 469.102v-23.464a.397.397 0 0 0-.794 0v23.464h.794z"
      style={{
        fill: "#263238",
      }}
    />
    <Path
      d="M366.142 464.642s-2.715-3.878-6.206-5.818c-3.491-1.939-9.308-1.939-9.308-1.939s3.103 11.247 15.514 7.757zM366.53 464.642s2.715-3.878 6.206-5.818c3.49-1.939 9.308-1.939 9.308-1.939s-3.103 11.247-15.514 7.757zM366.142 450.679s-2.715-3.878-6.206-5.818c-3.491-1.939-9.308-1.939-9.308-1.939s3.103 11.248 15.514 7.757zM366.53 450.679s2.715-3.878 6.206-5.818c3.49-1.939 9.308-1.939 9.308-1.939s-3.103 11.248-15.514 7.757zM366.53 445.637s-3.104-4.176-3.261-8.166c-.156-3.99 2.468-9.182 2.468-9.182s9.506 7.846.793 17.348zM434.468 469.102v-23.464a.397.397 0 1 0-.794 0v23.464h.794z"
      style={{
        fill: "#263238",
      }}
    />
    <Path
      d="M433.684 464.642s-2.715-3.878-6.206-5.818c-3.491-1.939-9.308-1.939-9.308-1.939s3.102 11.247 15.514 7.757zM434.071 464.642s2.715-3.878 6.206-5.818c3.49-1.939 9.308-1.939 9.308-1.939s-3.103 11.247-15.514 7.757zM433.684 450.679s-2.715-3.878-6.206-5.818c-3.491-1.939-9.308-1.939-9.308-1.939s3.102 11.248 15.514 7.757zM434.071 450.679s2.715-3.878 6.206-5.818c3.49-1.939 9.308-1.939 9.308-1.939s-3.103 11.248-15.514 7.757zM434.071 445.637s-3.104-4.176-3.261-8.166c-.156-3.99 2.467-9.182 2.467-9.182s9.507 7.846.794 17.348z"
      style={{
        fill: "#263238",
      }}
    />
    <Path
      d="M475.24 469H482M117.888 469h348.125M40 469h68.66"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
  </Svg>
)
export default Business
