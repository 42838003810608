import { StyleSheet, Platform } from 'react-native';

const colors = {
	white: "#000000",
	black: "#ffffff",
	blue: "#55A",
	commonBackgroundColor: "#dddddd",
}

const neumorphicText1 = Platform.OS === 'web'
	? {
		color: '#dddddd',
		textShadow: '4px -4px 8px #686868, -4px 4px 8px #ffffff',
	}
	: {
		color: '#eee',
		shadowColor: "#999",
		shadowOffset: {
			width: 5,
			height: 5,
		},
		shadowOpacity: 1,
		shadowRadius: 2,
		elevation: 2
	};

export default StyleSheet.create({
	backgroundView: {
		backgroundColor: 'white',
		width: '100%',
		height: '100%',
	},
	headerView: {
		marginTop:20
	},
	backgroundImage: {
		flex: 1,
		resizeMode: "contain",
		width: undefined,
		height: undefined
	},
	neumorphicText1: neumorphicText1,
});
