import { Text, TouchableOpacity, View, StyleSheet, Image,  } from 'react-native';
import logo from '../assets/eseme-transparent-black-2.png';
const uiTexts = require('../business-logic/ui-texts-english.json').header;

export default function HeaderView(props) {
	return (
		<View style={styles.container}>
			<Image
				style={styles.tinyLogo}
				source={logo}
			/>
			<View style={styles.menuContainer}>
				<TouchableOpacity style={styles.marginRight} onPress={props.scrollToServices}>
					<Text style={styles.text}>{uiTexts.Hizmetler}</Text>
				</TouchableOpacity>
				<TouchableOpacity style={styles.marginRight} onPress={props.scrollToProjects}>
					<Text style={styles.text}>{uiTexts.Projects}</Text>
				</TouchableOpacity>
				<TouchableOpacity onPress={props.scrollToContact}>
					<Text style={styles.text}>{uiTexts.Contact}</Text>
				</TouchableOpacity>
			</View>
		</View>
	);
}
const styles = StyleSheet.create({
	container: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'center',
		paddingTop: 20,
		paddingBottom: 20,
		paddingRight: 20,
	},
	tinyLogo: {
		width: 60,
		height: 36,
		resizeMode: 'contain'
	},
	menuContainer: {
		flexDirection: 'row',
		marginLeft: 'auto'
	},
	marginRight: {
		marginRight: 20
	},
	text: {
		fontSize: 15,
		fontWeight: 'bold'
	}

});