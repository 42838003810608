import { createSlice } from '@reduxjs/toolkit'

const curSlice = createSlice({
	name: 'heightSize',  // Step 2
	initialState: {
		state: 100  // Step 3 // Initial level
	},
	reducers: {
		changeStateOfHeightSize: (state, action) => { // Step 4
			state.state = action.payload
		} 
	}
})

export const { changeStateOfHeightSize } = curSlice.actions; // Step 5
export default curSlice.reducer;
