import * as React from "react"
import Svg, { Path, Defs, Use, ClipPath, Circle } from "react-native-svg"
const SectionFour = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    style={{
      enableBackground: "new 0 0 500 500",
    }}
    viewBox="0 0 500 500"
    {...props}
  >
    <Path
      d="M471.001 277.657c-6.289 120.834-86.031 119.092-178.588 146.137-42.893 12.533-87.187 36.981-124.97 36.981-119.314 0-148.222-105.148-135.475-223.779C45.105 114.735 117.63 41.384 242.944 53.859c118.727 11.82 234.928 91.76 228.057 223.798z"
      style={{
        fill: "#92e3a9",
      }}
    />
    <Path
      d="M471.001 277.657c-6.289 120.834-86.031 119.092-178.588 146.137-42.893 12.533-87.187 36.981-124.97 36.981-119.314 0-148.222-105.148-135.475-223.779C45.105 114.735 117.63 41.384 242.944 53.859c118.727 11.82 234.928 91.76 228.057 223.798z"
      style={{
        opacity: 0.75,
        fill: "#fff",
      }}
    />
    <Path
      d="M40 441.464h420"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
    />
    <Path
      d="M196.477 326.399H64.804c-5.533 0-10.018-4.485-10.018-10.018V47.389c0-5.533 4.485-10.018 10.018-10.018h131.673c5.533 0 10.018 4.485 10.018 10.018V316.38c.001 5.534-4.485 10.019-10.018 10.019z"
      style={{
        fill: "#263238",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
    />
    <Path
      d="M133.15 48.923a2.51 2.51 0 1 1-5.02 0 2.51 2.51 0 0 1 5.02 0z"
      style={{
        fill: "#92e3a9",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
    />
    <Defs>
      <Path
        id="a"
        d="m158.753 49.97-1.557 3.584a7.03 7.03 0 0 1-6.448 4.229h-40.215a7.031 7.031 0 0 1-6.448-4.229l-1.557-3.584a7.03 7.03 0 0 0-6.448-4.229H68.272a5.117 5.117 0 0 0-5.116 5.116v262.056a5.116 5.116 0 0 0 5.116 5.116H193.01a5.116 5.116 0 0 0 5.116-5.116V50.857a5.116 5.116 0 0 0-5.116-5.116h-27.809a7.03 7.03 0 0 0-6.448 4.229z"
      />
    </Defs>
    <Use
      xlinkHref="#a"
      style={{
        overflow: "visible",
        fill: "#92e3a9",
      }}
    />
    <ClipPath id="b">
      <Use
        xlinkHref="#a"
        style={{
          overflow: "visible",
        }}
      />
    </ClipPath>
    <Path
      d="m158.753 49.97-1.557 3.584a7.03 7.03 0 0 1-6.448 4.229h-40.215a7.031 7.031 0 0 1-6.448-4.229l-1.557-3.584a7.03 7.03 0 0 0-6.448-4.229H68.272a5.117 5.117 0 0 0-5.116 5.116v262.056a5.116 5.116 0 0 0 5.116 5.116H193.01a5.116 5.116 0 0 0 5.116-5.116V50.857a5.116 5.116 0 0 0-5.116-5.116h-27.809a7.03 7.03 0 0 0-6.448 4.229z"
      style={{
        opacity: 0.5,
        clipPath: "url(#b)",
        fill: "#fff",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
    />
    <Use
      xlinkHref="#a"
      style={{
        overflow: "visible",
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
    />
    <Path
      d="M189.407 87.264H71.874a4.533 4.533 0 0 1-4.533-4.533V68.76a4.532 4.532 0 0 1 4.533-4.533h117.533a4.533 4.533 0 0 1 4.533 4.533v13.97a4.533 4.533 0 0 1-4.533 4.534z"
      style={{
        fill: "#fff",
        stroke: "#263238",
        strokeWidth: 0.75,
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
    />
    <Path
      d="M134.687 76.623a3.481 3.481 0 1 0-5.31-.465.657.657 0 0 0-.221.145l-3.389 3.389a.655.655 0 1 0 .927.927l3.389-3.389a.657.657 0 0 0 .145-.221 3.478 3.478 0 0 0 4.459-.386zm-4.314-.61a2.619 2.619 0 1 1 3.703-3.704 2.619 2.619 0 0 1-3.703 3.704z"
      style={{
        fill: "#263238",
      }}
    />
    <Path
      d="M87.75 71.088H74.216a.875.875 0 0 1-.875-.875h0c0-.483.392-.875.875-.875H87.75c.483 0 .875.392.875.875h0a.873.873 0 0 1-.875.875zM86 76.102H74.216a.875.875 0 0 1-.875-.875h0c0-.483.392-.875.875-.875H86c.483 0 .875.392.875.875h0a.875.875 0 0 1-.875.875zM84.25 81.116H74.216a.875.875 0 0 1-.875-.875h0c0-.483.392-.875.875-.875H84.25c.483 0 .875.392.875.875h0a.875.875 0 0 1-.875.875z"
      style={{
        fill: "#263238",
        stroke: "#263238",
        strokeWidth: 0.75,
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
    />
    <Path
      d="M189.407 165.535H71.874a4.532 4.532 0 0 1-4.533-4.533V98.618a4.532 4.532 0 0 1 4.533-4.533h117.533a4.533 4.533 0 0 1 4.533 4.533v62.385a4.532 4.532 0 0 1-4.533 4.532z"
      style={{
        fill: "#fff",
        stroke: "#263238",
        strokeWidth: 0.75,
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
    />
    <Path
      d="M83.257 103.067h94.768v53.486H83.257z"
      style={{
        fill: "#92e3a9",
        stroke: "#263238",
        strokeWidth: 0.75,
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
    />
    <Path
      d="M149.165 135.475H91.062a1.569 1.569 0 0 1-1.569-1.569h0c0-.867.703-1.569 1.569-1.569h58.103c.867 0 1.569.703 1.569 1.569h0c0 .866-.702 1.569-1.569 1.569zM144.587 142.065H91.062a1.569 1.569 0 0 1-1.569-1.569h0c0-.867.703-1.57 1.569-1.57h53.525a1.57 1.57 0 0 1 1.569 1.57h0a1.568 1.568 0 0 1-1.569 1.569z"
      style={{
        fill: "#263238",
        stroke: "#263238",
        strokeWidth: 0.75,
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
    />
    <Path
      d="M120.113 150.887a1.36 1.36 0 1 1-2.718 0 1.36 1.36 0 0 1 2.718 0zM128.038 150.887a1.36 1.36 0 1 1-2.719 0 1.36 1.36 0 0 1 2.719 0zM135.962 150.887a1.36 1.36 0 1 1-2.718 0 1.36 1.36 0 0 1 2.718 0zM143.887 150.887a1.36 1.36 0 1 1-2.718 0 1.36 1.36 0 0 1 2.718 0z"
      style={{
        fill: "#fff",
      }}
    />
    <Path
      d="M183.256 136.405a1.044 1.044 0 0 1-.812-1.705l3.967-4.89-3.967-4.889a1.045 1.045 0 1 1 1.624-1.318l4.502 5.549c.312.384.312.934 0 1.318l-4.502 5.549a1.046 1.046 0 0 1-.812.386zM78.025 136.405c-.304 0-.606-.132-.813-.387l-4.503-5.549a1.045 1.045 0 0 1 0-1.318l4.503-5.549a1.046 1.046 0 0 1 1.624 1.318l-3.968 4.889 3.968 4.89a1.045 1.045 0 0 1-.811 1.706z"
      style={{
        fill: "#92e3a9",
      }}
    />
    <Path
      d="M88.776 201.401H77.503a8.07 8.07 0 0 1-8.07-8.07v-11.272a8.07 8.07 0 0 1 8.07-8.07h11.272a8.07 8.07 0 0 1 8.07 8.07v11.272a8.07 8.07 0 0 1-8.069 8.07z"
      style={{
        fill: "#92e3a9",
        stroke: "#263238",
        strokeWidth: 0.75,
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
    />
    <Path
      d="M83.139 196.138a8.443 8.443 0 1 1 0-16.886 8.443 8.443 0 1 1 0 16.886z"
      style={{
        opacity: 0.5,
        fill: "#fff",
      }}
    />
    <Path
      d="M95.276 209.668H71.003a1.569 1.569 0 0 1-1.569-1.569h0c0-.867.703-1.569 1.569-1.569h24.273c.867 0 1.569.703 1.569 1.569h0c0 .866-.702 1.569-1.569 1.569z"
      style={{
        fill: "#263238",
        stroke: "#263238",
        strokeWidth: 0.75,
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
    />
    <Path
      d="M189.407 259.882H71.874a4.533 4.533 0 0 1-4.533-4.533v-30.623a4.532 4.532 0 0 1 4.533-4.533h117.533a4.533 4.533 0 0 1 4.533 4.533v30.623a4.533 4.533 0 0 1-4.533 4.533z"
      style={{
        fill: "#fff",
        stroke: "#263238",
        strokeWidth: 0.75,
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
    />
    <Path
      d="M73.619 226.47h41.17v27.134h-41.17z"
      style={{
        fill: "#92e3a9",
        stroke: "#263238",
        strokeWidth: 0.75,
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
    />
    <Path
      d="M179.236 229.693h-58.103a1.569 1.569 0 0 1-1.569-1.569h0c0-.867.703-1.569 1.569-1.569h58.103c.867 0 1.569.703 1.569 1.569h0a1.568 1.568 0 0 1-1.569 1.569zM174.659 236.284h-53.525a1.569 1.569 0 0 1-1.569-1.569h0c0-.867.703-1.569 1.569-1.569h53.525c.867 0 1.569.703 1.569 1.569h0c0 .866-.702 1.569-1.569 1.569zM177.667 242.874h-56.534a1.569 1.569 0 0 1-1.569-1.569h0c0-.867.703-1.569 1.569-1.569h56.534c.867 0 1.569.703 1.569 1.569h0c0 .866-.702 1.569-1.569 1.569zM167.727 249.464h-46.594a1.569 1.569 0 0 1-1.569-1.569h0c0-.867.703-1.569 1.569-1.569h46.594c.867 0 1.569.703 1.569 1.569h0a1.568 1.568 0 0 1-1.569 1.569z"
      style={{
        fill: "#263238",
        stroke: "#263238",
        strokeWidth: 0.75,
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
    />
    <Path
      d="M189.407 305.735H71.874a4.533 4.533 0 0 1-4.533-4.533v-30.623a4.532 4.532 0 0 1 4.533-4.533h117.533a4.533 4.533 0 0 1 4.533 4.533v30.623a4.533 4.533 0 0 1-4.533 4.533z"
      style={{
        fill: "#fff",
        stroke: "#263238",
        strokeWidth: 0.75,
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
    />
    <Path
      d="M73.619 272.323h41.17v27.134h-41.17z"
      style={{
        fill: "#92e3a9",
        stroke: "#263238",
        strokeWidth: 0.75,
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
    />
    <Path
      d="M179.236 275.547h-58.103a1.569 1.569 0 0 1-1.569-1.569h0c0-.867.703-1.569 1.569-1.569h58.103c.867 0 1.569.703 1.569 1.569h0a1.568 1.568 0 0 1-1.569 1.569zM183.029 282.137h-61.896a1.569 1.569 0 0 1-1.569-1.569h0c0-.867.703-1.569 1.569-1.569h61.896c.867 0 1.569.703 1.569 1.569h0a1.568 1.568 0 0 1-1.569 1.569zM171.389 295.317h-50.256a1.569 1.569 0 0 1-1.569-1.569h0c0-.867.703-1.569 1.569-1.569h50.256c.867 0 1.569.703 1.569 1.569h0a1.568 1.568 0 0 1-1.569 1.569zM178.19 288.824h-57.057a1.569 1.569 0 0 1-1.569-1.569h0c0-.867.703-1.569 1.569-1.569h57.057c.867 0 1.569.703 1.569 1.569h0a1.568 1.568 0 0 1-1.569 1.569z"
      style={{
        fill: "#263238",
        stroke: "#263238",
        strokeWidth: 0.75,
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
    />
    <Path
      d="M152.075 201.401h-11.272a8.07 8.07 0 0 1-8.07-8.07v-11.272a8.07 8.07 0 0 1 8.07-8.07h11.272a8.07 8.07 0 0 1 8.07 8.07v11.272a8.07 8.07 0 0 1-8.07 8.07z"
      style={{
        fill: "#92e3a9",
        stroke: "#263238",
        strokeWidth: 0.75,
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
    />
    <Path
      d="M146.439 196.138a8.443 8.443 0 1 1 0-16.886 8.443 8.443 0 1 1 0 16.886z"
      style={{
        opacity: 0.5,
        fill: "#fff",
      }}
    />
    <Path
      d="M158.575 209.668h-24.273a1.569 1.569 0 0 1-1.569-1.569h0c0-.867.703-1.569 1.569-1.569h24.273c.867 0 1.569.703 1.569 1.569h0a1.568 1.568 0 0 1-1.569 1.569z"
      style={{
        fill: "#263238",
        stroke: "#263238",
        strokeWidth: 0.75,
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
    />
    <Path
      d="M183.725 201.401h-11.272a8.07 8.07 0 0 1-8.07-8.07v-11.272a8.07 8.07 0 0 1 8.07-8.07h11.272a8.07 8.07 0 0 1 8.07 8.07v11.272a8.07 8.07 0 0 1-8.07 8.07z"
      style={{
        fill: "#92e3a9",
        stroke: "#263238",
        strokeWidth: 0.75,
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
    />
    <Path
      d="M178.089 196.138a8.443 8.443 0 1 1 0-16.886 8.443 8.443 0 1 1 0 16.886z"
      style={{
        opacity: 0.5,
        fill: "#fff",
      }}
    />
    <Path
      d="M190.225 209.668h-24.273a1.569 1.569 0 0 1-1.569-1.569h0c0-.867.703-1.569 1.569-1.569h24.273c.867 0 1.569.703 1.569 1.569h0a1.568 1.568 0 0 1-1.569 1.569z"
      style={{
        fill: "#263238",
        stroke: "#263238",
        strokeWidth: 0.75,
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
    />
    <Path
      d="M120.425 201.401h-11.272a8.07 8.07 0 0 1-8.07-8.07v-11.272a8.07 8.07 0 0 1 8.07-8.07h11.272a8.07 8.07 0 0 1 8.07 8.07v11.272a8.07 8.07 0 0 1-8.07 8.07z"
      style={{
        fill: "#92e3a9",
        stroke: "#263238",
        strokeWidth: 0.75,
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
    />
    <Path
      d="M126.926 209.668h-24.273a1.569 1.569 0 0 1-1.569-1.569h0c0-.867.703-1.569 1.569-1.569h24.273c.867 0 1.569.703 1.569 1.569h0a1.57 1.57 0 0 1-1.569 1.569z"
      style={{
        fill: "#263238",
        stroke: "#263238",
        strokeWidth: 0.75,
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
    />
    <Path
      d="M114.789 196.138a8.443 8.443 0 1 1 0-16.886 8.443 8.443 0 1 1 0 16.886z"
      style={{
        opacity: 0.5,
        fill: "#fff",
      }}
    />
    <Defs>
      <Path
        id="c"
        d="M159.182 432.2h-12.835a11.816 11.816 0 0 1-11.775-10.827l-2.538-25.635h41.461l-2.538 25.635a11.817 11.817 0 0 1-11.775 10.827z"
      />
    </Defs>
    <Use
      xlinkHref="#c"
      style={{
        overflow: "visible",
        fill: "#fff",
      }}
    />
    <ClipPath id="d">
      <Use
        xlinkHref="#c"
        style={{
          overflow: "visible",
        }}
      />
    </ClipPath>
    <Path
      d="M130.098 407h47.723v6.969h-47.723z"
      style={{
        clipPath: "url(#d)",
        fill: "#92e3a9",
      }}
    />
    <Use
      xlinkHref="#c"
      style={{
        overflow: "visible",
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
    />
    <Path
      d="M159.182 432.2h-12.835c-2.186 0-4.236-.605-6.001-1.648l-.343 4.089h25.524l-.343-4.089a11.763 11.763 0 0 1-6.002 1.648zM178.026 399.454c-1.805-.435-3.52.327-5.076 1.781l-.512 5.17c1.424-2.349 3.113-3.973 4.876-3.549 3.762.906 5.094 5.706 3.895 10.689-1.199 4.983-5.221 8.288-8.983 7.382-.442-.106-.838-.196-1.199-.28l-.072.726a11.858 11.858 0 0 1-.39 2.174c.162.038.314.073.483.114 5.862 1.411 11.918-2.864 13.527-9.548 1.611-6.685-.687-13.247-6.549-14.659z"
      style={{
        fill: "#fff",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
    />
    <Path
      d="M152.845 428.917c-4.171.634-8.787 4.065-8.287 7.357.5 3.291 5.927 5.196 10.098 4.562 3.365-.511 3.991-3.342 5.821-5.117 5.352-5.193 25.978-5.1 33.65-4.875a2.416 2.416 0 0 0 2.456-2.782h0a2.42 2.42 0 0 0-2.666-2.04c-6.356.729-24.151 2.878-32.479 4.982-2.471.624-5.229-2.599-8.593-2.087z"
      style={{
        fill: "#fff",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
    />
    <Path
      d="M166.666 441.901h-29.542c-4.495 0-8.95-.858-13.124-2.527l-9.589-3.836c-1.09-.436-.778-2.057.396-2.057h74.175c1.174 0 1.486 1.621.396 2.057l-9.589 3.836a35.339 35.339 0 0 1-13.123 2.527z"
      style={{
        fill: "#fff",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
    />
    <Path
      d="M153.486 343.596c-1.547-2.543-3.472-3.643-3.472-3.643M152.298 362.086c3.163-6.31 3.513-11.064 2.763-14.516M147.205 380.888c-.789-4.283-.171-9.668 3.613-16.091M154.868 392.915s-4.756-3.174-6.917-9.247M164.419 354.83c-1.25-2.43-3.065-3.468-3.065-3.468M163.564 367.859c2.016-4.357 2.285-7.721 1.807-10.239M165.162 392.915s-11.61-7.748-3.178-22.061M143.462 366.811a8.998 8.998 0 0 0-.381-2.588M140.634 375.54c1.343-2.279 2.122-4.264 2.511-5.981M143.137 392.915s-7.834-5.228-3.734-14.945"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
    />
    <Defs>
      <Path
        id="e"
        d="M217.881 316.466s-15.135 41.681-10.327 59.494c4.808 17.813 29.842 12.35 35.964 1.425 6.122-10.925 18.073-50.587 18.073-50.587l-12.348-23.75-27.356 6.175-2.584 3.8-1.422 3.443z"
      />
    </Defs>
    <Use
      xlinkHref="#e"
      style={{
        overflow: "visible",
        fill: "#858585",
      }}
    />
    <ClipPath id="f">
      <Use
        xlinkHref="#e"
        style={{
          overflow: "visible",
        }}
      />
    </ClipPath>
    <Path
      d="M242.106 311.003s-25.413 53.319-38 51.656 0 40.138 0 40.138h24.768l28.634-35.625 5.105-26.204 1.342-12.508-8.712-11.994-13.137-5.463z"
      style={{
        opacity: 0.2,
        clipPath: "url(#f)",
      }}
    />
    <Use
      xlinkHref="#e"
      style={{
        overflow: "visible",
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M359.728 162.888s5.802-5.27 1.476-10.812c0 0 5.79-4.117 1.603-11.242 0 0 6.365-8.075-1.603-13.458 0 0 4.065-8.392-5.276-8.233 0 0 1.583-8.867-7.758-5.542 0 0 .633-8.867-7.283-4.908 0 0-3.483-7.917-7.6.317 0 0-3.958-10.45-10.925-6.808 0 0-13.458-6.175-15.358 1.742 0 0-8.709-2.217-11.004 3.8 0 0-9.737-1.267-10.212 7.125 0 0-9.183 3.167-6.333 9.975 0 0-4.105 5.383-.549 10.767 0 0-3.41 3.642.549 10.292 0 0-1.742 6.808 3.008 11.717l57.792 9.5 2.692 13.142 11.209-4.433 5.572-12.941z"
      style={{
        fill: "#263238",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M323.275 107.346c-2.104-2.729-5.978-5.106-12.789-2.137M325.528 112.018s-.23-1.042-.865-2.383M315.553 111.384s-10.292 6.333-14.092-2.692M332.056 119.209s-11.497 3.72-13.055-5.948M322.863 127.237c-2.027-2.889-5.94-5.663-13.09-2.546M324.814 131.499s-.23-1.04-.862-2.379M300.853 131.068c-.546-.646-1.036-1.447-1.449-2.429M313.495 131.332s-6.794 4.181-11.38.908M359.37 145.597s2.895-8.907-7.138-10.121M341.339 141.78s-3.733 6.648 4.083 9.219M353.16 141.395s-9.063.183-8.089-7.098M352.232 155.228s-6.456-6.363-.562-10.747M303.259 125.781s2.895-8.907-7.138-10.121M285.229 121.964s-3.733 6.648 4.083 9.219M297.05 121.579s-9.063.183-8.089-7.098M296.121 135.412s-6.456-6.363-.562-10.747M352.202 126.616s2.895-8.907-7.138-10.121M334.171 122.799s-3.733 6.649 4.083 9.219M345.992 122.414s-9.063.182-8.089-7.098"
      style={{
        fill: "none",
        stroke: "#7f7f7f",
        strokeWidth: 0.75,
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
    />
    <Defs>
      <Path
        id="g"
        d="M415.78 256.214s-15.974 84.03-18.396 185.25H253.459s16.316-85.93-1.973-198.03c.001 0 74.207-52.816 164.294 12.78z"
      />
    </Defs>
    <Use
      xlinkHref="#g"
      style={{
        overflow: "visible",
        fill: "#92e3a9",
      }}
    />
    <ClipPath id="h">
      <Use
        xlinkHref="#g"
        style={{
          overflow: "visible",
        }}
      />
    </ClipPath>
    <Path
      d="M410.086 264.333s-18.355 29.61-21.522 57.714c-3.167 28.104-3.167 46.787-3.167 46.787L303.7 347.34s48.763 42.71 75.68 59.177c26.917 16.467 20.516 39.9 20.516 39.9l19.384-72.833v-48.925l-3.501-50.825-5.693-9.501zM338.448 258.221l-17.227-13.203-18.189 12.547-5.512-18.247 17.383-6.809 22.678 2.692 7.6 10.164z"
      style={{
        opacity: 0.2,
        clipPath: "url(#h)",
      }}
    />
    <Use
      xlinkHref="#g"
      style={{
        overflow: "visible",
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Defs>
      <Path
        id="i"
        d="M437.155 326.401s2.229 58.799-18.531 79.167c0 0-24.935 5.7-97.843-49.717 0 0-7.239.633-11.032-3.8 0 0-11.746 4.117-17.91-2.533 0 0-7.895.317-9.671-6.967 0 0-5.022.633-6.761-1.583 0 0-5.995.317-6.849-2.85-.855-3.167 5.933-5.719 5.933-5.719s.125-7.264 3.137-9.481c3.012-2.217 5.795-3.167 5.795-3.167s-19.923-12.033-17.497-18.683c2.426-6.65 5.778 5.067 24.721 13.3 0 0 9.522.317 11.349 3.8l23.058 19.317s6.738 1.794 8.949 2.533c14.324 4.785 34.418 13.3 58.278 14.883l4.626-38s28.963-6.967 40.248 9.5z"
      />
    </Defs>
    <Use
      xlinkHref="#i"
      style={{
        overflow: "visible",
        fill: "#858585",
      }}
    />
    <ClipPath id="j">
      <Use
        xlinkHref="#i"
        style={{
          overflow: "visible",
        }}
      />
    </ClipPath>
    <Path
      d="m406.683 316.466-6.786 38.717-7.616-.282-3.242-5.383 2.217-27.867 7.89-12.825 5.885 1.267zM433.897 370.08s-7.174 23.454-15.882 32.004c0 0 7.917-12.35-26.917-21.217-34.833-8.867-68.242-29.846-68.242-29.846s-12.039-9.574-17.419-7.815c-5.381 1.759-3.197 4.49-1.736 4.134 1.461-.356 3.48.198 3.599 2.177.119 1.979.195 3.364-2.165 3.568-2.36.204-.447 7.08-.447 7.08l42.394 38.356 31.667 7.212 43.997 8.067 14.479-15.279 4.54-20.126-4.54-9.245-3.328.93zM300.8 353.28s-2.169-1.466-1.931-3.762c.237-2.296-4.622 1.064-7.299-2.516 0 0-5.678-.374-9.402-7.38 0 0-6.165 1.076-7.082-1.659 0 0-.442-8.435-1.155-8.078-.712.356-2.494 1.543-2.494 1.543l1.781 11.124 10.205 8.352 11.862 4.75h5.515v-2.374zM281.056 319.751s2.549.263 8.832-2.206c0 0-9.251-2.341-15.248-7.146s-7.39-10.083-8.918-7.827c-1.528 2.256 4.766 9.262 4.766 9.262l10.568 7.917z"
      style={{
        opacity: 0.2,
        clipPath: "url(#j)",
      }}
    />
    <Use
      xlinkHref="#i"
      style={{
        overflow: "visible",
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M394.557 355.183c8.094.393 11.448-1.639 18.217 7.477M295.285 324.659s-11.645 4.987-12.269 7.956c-.624 2.969-.849 9.935-.849 9.935M301.45 331.309s-.799 2.083-3.288 3.919c-3.275 2.415-6.934 2.731-6.964 5.7-.03 2.969.64 8.59.64 8.59M274.491 332.399l.916 8.569M283.423 319.751l9.627-3.285M306.602 342.155s-3.188 3.088-5.802 4.196"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M251.487 243.434s-33.784 13.401-35.453 72.762c-.036 1.267 1.39 2.044 2.421 1.306 5.205-3.725 18.689-8.745 41.303 13.297 1.057 1.031 2.838.283 2.848-1.193l.001-.078c.045-6.329-.072-19.396-1.396-34.695-1.421-16.417-4.232-35.405-9.724-51.399z"
      style={{
        fill: "#92e3a9",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M224.819 306.301c7.903-1.459 22.138-.875 36.44 15.746M218.496 308.272s1.333-.698 3.667-1.355"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Defs>
      <Path
        id="k"
        d="M358.461 229.897s-11.163-17.338-8.075-47.263l-45.649 3.008s-.402 39.58-15.02 41.68l11.919 15.248 10.513.778 18.169.086 26.481-8.55 1.662-4.987z"
      />
    </Defs>
    <Use
      xlinkHref="#k"
      style={{
        overflow: "visible",
        fill: "#858585",
      }}
    />
    <ClipPath id="l">
      <Use
        xlinkHref="#k"
        style={{
          overflow: "visible",
        }}
      />
    </ClipPath>
    <Path
      d="M358.065 185.643c-2.167 3.044-5.146 4.295-8.913 1.736 0 0-.017 16.947-10.688 27.788-10.671 10.84-27.269 15.977-35.862 15.977-8.594 0-21.57-25.952-23.729-39.252a71.265 71.265 0 0 1-.549-4.348l79.741-1.901z"
      style={{
        clipPath: "url(#l)",
        fill: "#263238",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Use
      xlinkHref="#k"
      style={{
        overflow: "visible",
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M349.846 166.959s2.677-10.45 8.615-9.025 2.137 35.387-10.45 26.838c0 0-.017 16.947-10.688 27.787-10.671 10.84-22.519 14.077-31.112 14.077s-21.57-25.952-23.729-39.252c-2.159-13.3-1.446-43.938 1.641-47.737 3.087-3.8 41.069.712 49.51-2.85 8.44-3.563-1.06 15.2 3.453 19.712 4.512 4.513 7.841.95 6.889 19l3.798-3.563 2.073-4.987z"
      style={{
        fill: "#858585",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M314.903 175.589c0 1.691 1.07 3.061 2.391 3.061s2.391-1.37 2.391-3.061c0-1.691-1.07-3.061-2.391-3.061-1.321-.001-2.391 1.37-2.391 3.061zM290.062 175.589c0 1.691 1.07 3.061 2.391 3.061 1.32 0 2.391-1.37 2.391-3.061 0-1.691-1.07-3.061-2.391-3.061-1.32-.001-2.391 1.37-2.391 3.061z"
      style={{
        fill: "#263238",
      }}
    />
    <Path
      d="M330.278 164.584s-7.125-7.442-18.05 0M296.87 165.376s-8.867-9.324-12.667-2.445"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeWidth: 4,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M325.361 200.584s-21.299 7.103-30.392 1.05c.001 0 11.469 19.866 30.392-1.05z"
      style={{
        fill: "#fff",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M313.495 185.643s3.449 3.136 1.261 5.84c-.96 1.187-2.602 1.552-4.062 1.107-1.173-.357-2.767-.519-3.848.652-1.9 2.058-6.808 1.583-8.392-.95 0 0-1.214-.455-2.276-.473a2.205 2.205 0 0 1-2.14-1.857c-.204-1.326.219-3.013 2.833-4.32M300.353 170.466s1.798 5.835 1.137 11.851"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M320.936 200.241s-9.182-2.166-14.692.456c-.654.311-1.418.313-2.022-.086-1.155-.761-3.501-1.726-7.352-.874"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeWidth: 0.75,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M358.146 227.639c-4.194 3.73-18.836 11.699-38.496 15.796l8.887 1.425 11.275 15.2s23.235-12.595 28.49-29.929l-5.211-2.901c-1.645-.916-3.627-.764-4.945.409z"
      style={{
        fill: "#92e3a9",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M292.216 226.643c3.509 4.465 8.628 12.88 27.946 16.791l-8.688 1.425-10.1 15.2s-17.037-11.4-18.558-31.829l4.364-2.635c1.613-.973 3.796-.531 5.036 1.048z"
      style={{
        fill: "#92e3a9",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M322.361 177.251s-4.611 2.375-10.133 0M297.52 177.251s-4.611 2.375-10.133 0"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeWidth: 0.75,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M415.78 256.214s25.671 9.314 22.929 72.57c-.046 1.062-1.387 1.521-2.049.689-3.844-4.833-14.442-13.433-39.05-7.958a1.429 1.429 0 0 1-1.727-1.551c1.029-9.417 6.133-49.443 19.897-63.75z"
      style={{
        fill: "#92e3a9",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M399.897 312.899c-.496.112-.997.233-1.503.361M411.801 311.555c-3.121-.029-6.531.262-10.237.99M431.607 317.025c-3.943-2.444-9.823-4.953-17.723-5.401M436.607 320.871a28.085 28.085 0 0 0-3.051-2.54"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M274.639 310.399c-.265.375-.718.604-1.309.604h-23.137c-1.238 0-2.599-1.003-3.04-2.241l-18.139-50.855c-.221-.619-.17-1.179.091-1.585l1.814-1.96c.265-.37.717-.596 1.303-.596h23.137c1.238 0 2.599 1.003 3.041 2.241l18.139 50.855c.221.619.17 1.179-.091 1.585l-1.809 1.952z"
      style={{
        fill: "#fff",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M250.192 311.003h23.137c1.238 0 1.883-1.003 1.442-2.241l-18.139-50.855c-.441-1.238-1.803-2.241-3.04-2.241h-23.137c-1.238 0-1.883 1.003-1.442 2.241l18.139 50.855c.442 1.238 1.803 2.241 3.04 2.241z"
      style={{
        fill: "#263238",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M250.435 267.54h5.493c1.238 0 1.883-1.003 1.442-2.241l-1.959-5.493c-.441-1.238-1.803-2.241-3.04-2.241h-5.493c-1.238 0-1.883 1.003-1.442 2.241l1.959 5.493c.441 1.238 1.802 2.241 3.04 2.241z"
      style={{
        fill: "#858585",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path d="M246.896 260.237c.257.721 1.051 1.306 1.772 1.306.721 0 1.098-.585.84-1.306-.257-.721-1.051-1.306-1.772-1.306-.722 0-1.098.585-.84 1.306zM252.551 262.553c.257.721 1.051 1.306 1.772 1.306.721 0 1.098-.585.84-1.306-.257-.721-1.051-1.306-1.772-1.306-.721 0-1.098.585-.84 1.306zM248.547 264.869c.257.721 1.051 1.306 1.772 1.306.721 0 1.098-.585.84-1.306-.257-.722-1.051-1.306-1.772-1.306-.721-.001-1.097.584-.84 1.306z" />
    <Defs>
      <Path
        id="m"
        d="M229.756 316.466s-22.906 42.631-22.202 59.494c.704 16.863 11.315 22.563 11.315 22.563s28.638-1.425 29.113-35.387l3.867-33.013s9.974-3.404 14-10.371c0 0 9.974-3.404 13.138-9.104 3.164-5.7 5.024-13.063 1.853-14.25s-5.348 9.205-8.086 11.163c-1.838 1.314-4.769 3.004-6.497 3.974-.741.415-1.72.047-2.077-.775-.266-.613-.098-1.295.413-1.667 1.367-.994 4.084-3.154 6.262-6.045 3.043-4.038 11.491-15.675 7.271-18.288-4.22-2.613-11.161 10.212-13.861 12.35-1.673 1.325-3.971 2.831-5.478 3.786-.91.576-2.135.437-2.988-.336l-.168-.152c-.854-.773-1.118-1.984-.642-2.954.804-1.639 2.014-4.024 2.639-4.851 1.826-2.417 4.251-4.734 8.029-9.062 3.913-4.484 7.38-7.652 4.739-9.706-5.191-4.037-18.615 11.163-20.382 13.063-1.107 1.19-2.155 3.218-2.784 4.568-.348.746-1.26 1.017-2.029.606a1.644 1.644 0 0 1-.871-1.301c-.158-1.885-.466-6.283-.061-7.91.587-2.357 2.271-4.043 4.706-8.36 2.136-3.788 4.617-7.787 2.836-9.452-3.812-3.563-11.368 7.362-11.368 7.362l-5.487 9.737s2.264-4.987-.515-7.362-2.625 3.087-5.043 5.938c-2.418 2.85-.103 8.788-.754 13.063s-.656 7.6-.656 7.6l1.768 15.079z"
      />
    </Defs>
    <Use
      xlinkHref="#m"
      style={{
        overflow: "visible",
        fill: "#858585",
      }}
    />
    <ClipPath id="n">
      <Use
        xlinkHref="#m"
        style={{
          overflow: "visible",
        }}
      />
    </ClipPath>
    <Path
      d="M257.509 327.449s6.753-6.867 3.75-9.341c-3.003-2.474-5.65-4.557-8.483-8.266-2.833-3.708-4.732-8.696-8.295-8.696s-6.511-4.796-10.38-2.042c-3.87 2.754 1.02 15.5 0 20.646-1.02 5.146 5.263 91.641-24.122 67.75-29.385-23.891 6.952 27.409 6.952 27.409l24.7-5.462 6.571-11.875 3.691-26.6 4.701-34.289.915-9.234zM269.364 300.455c-.62-.496-1.394-1.197-2.047-2.039-.543-.702-1.615-.673-2.135.046-1.255 1.734-3.401 4.329-5.739 5.535 0 0 2.137 4.75 4.275 3.8 1.53-.68 4.339-3.734 5.811-5.415a1.337 1.337 0 0 0-.165-1.927zM255.507 290.813c-1.05-.401-2.626-1.153-3.626-2.328a.744.744 0 0 0-1.23.136l-2.449 4.688s1.749 2.743 4.268 3.444c0 0 1.65-2.82 3.322-4.74a.749.749 0 0 0-.285-1.2zM243.175 281.672s-2.908 3.8-5.7 2.731v6.769l5.7-.356c0-.001-1.069-3.444 0-9.144zM276.187 312.428s-4.785 2.064-8.351 2.189c-.75.026-1.404.515-1.578 1.245l-.537 2.246s4.529-.574 10.466-5.68zM235.502 280.529s-1.709 3.636-3.728 5.061c-2.019 1.425.238 2.417 0 5.721.001 0 3.062-8.243 3.728-10.782z"
      style={{
        opacity: 0.2,
        clipPath: "url(#n)",
      }}
    />
    <Use
      xlinkHref="#m"
      style={{
        overflow: "visible",
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M234.683 283.334s-2.77 9.5-4.404 9.975M373.523 426.303c1.302.162 2.622.323 3.958.481M315.714 415.523c13.1 3.6 30.289 7.147 52.813 10.137M278.921 399.738c4.657 3.656 13.943 9.028 32.038 14.424M274.491 395.118s.443.952 2.068 2.558"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="m324.791 248.197-1.699 48.644h0c-4.91 0-8.751-3.98-8.579-8.89l1.388-39.754"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeWidth: 0.75,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M323.161 252.667a3 3 0 1 1-5.999 0 3 3 0 0 1 5.999 0z"
      style={{
        fill: "#92e3a9",
        stroke: "#263238",
        strokeWidth: 0.75,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Circle
      cx={318.856}
      cy={285.383}
      r={2.999}
      style={{
        fill: "#92e3a9",
        stroke: "#263238",
        strokeWidth: 0.75,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M324.089 198.917s1.577 2.25 3.327 2.917"
      style={{
        fill: "#858585",
        stroke: "#263238",
        strokeWidth: 0.75,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M298.155 205.553s1.428 1.738 5.178 1.863"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeWidth: 0.75,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M325.361 200.584s-3.57 2.91-7.653 4.788"
      style={{
        fill: "#858585",
        stroke: "#263238",
        strokeWidth: 0.75,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M296.167 205.803s3.306 11.031 13.736 9.697c10.43-1.333 14.186-11.387 14.186-11.387"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeWidth: 0.75,
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeMiterlimit: 10,
      }}
    />
    <Path
      d="M422.256 133.065h-31.29a1.35 1.35 0 0 1-1.35-1.35h0c0-.746.604-1.35 1.35-1.35h31.29c.746 0 1.35.604 1.35 1.35h0a1.35 1.35 0 0 1-1.35 1.35zM421.356 139.796h-30.39a1.35 1.35 0 0 1-1.35-1.35h0c0-.746.604-1.35 1.35-1.35h30.39c.746 0 1.35.604 1.35 1.35h0a1.35 1.35 0 0 1-1.35 1.35zM412.356 146.527h-21.39a1.35 1.35 0 0 1-1.35-1.35v0c0-.745.604-1.35 1.35-1.35h21.39c.746 0 1.35.604 1.35 1.35v0a1.35 1.35 0 0 1-1.35 1.35z"
      style={{
        fill: "#263238",
        stroke: "#263238",
        strokeWidth: 0.75,
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
    />
    <Path
      d="M426.151 152.988a4.43 4.43 0 0 0 4.429-4.429v-17.136M423.595 152.988h-17.042l-11.844 11.843v-11.843h-2.145M382.642 131.423v17.136a4.43 4.43 0 0 0 4.429 4.429h2.545M430.581 128.561a4.43 4.43 0 0 0-4.429-4.429h-39.081a4.43 4.43 0 0 0-4.429 4.429M446.857 187.064a4.43 4.43 0 0 0-4.429-4.429H377M396.5 211.49h45.927a4.43 4.43 0 0 0 4.429-4.429v-16.974M381.694 217.667v5.667l11.844-11.844M369.626 207.061a4.43 4.43 0 0 0 4.429 4.429h7.638v3.707M374.056 182.634a4.43 4.43 0 0 0-4.429 4.429v16.92M385.469 202.396l-5.393 2.835 1.03-6.004-4.363-4.253 6.029-.876 2.697-5.463 2.696 5.463 6.029.876-4.363 4.253 1.03 6.004z"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeWidth: 0.75,
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
    />
    <Path
      d="m408.241 202.396-5.392 2.835 1.03-6.004-4.363-4.253 6.029-.876 2.696-5.463 2.697 5.463 6.029.876-4.363 4.253 1.03 6.004zM431.014 202.396l-5.392 2.835 1.03-6.004-4.363-4.253 6.029-.876 2.696-5.463 2.697 5.463 6.029.876-4.363 4.253 1.03 6.004zM238.977 170.375v-18.589h4.058v20.442c0 4.287-2.144 6.737-6.277 6.737-4.136 0-6.281-2.45-6.281-6.737v-20.442h4.211v20.748c0 1.914.804 2.603 2.145 2.603 1.34 0 2.144-.689 2.144-2.603M255.558 167.352l3.985 11.23h-4.442l-3.061-9.34h-.078l-3.139 9.34h-3.98l4.862-13.703-4.632-13.092h4.438l2.833 8.651h.078l2.908-8.651h3.98l-4.632 13.092M244.166 92.965l5.856-2.526v3.062l-8.076 3.407 8.076 3.407v3.062l-11.713-5.052v-2.833l3.727-1.607M256.347 100.406l6.66-16.895h3.637l-10.566 26.796h-3.634l2.731-6.929M271.08 102.507l-2.017.87v-3.062l8.079-3.407-8.079-3.407v-3.062l11.713 5.052v2.833l-6.731 2.903"
      style={{
        fill: "none",
        stroke: "#263238",
        strokeWidth: 0.75,
        strokeLinecap: "round",
        strokeLinejoin: "round",
      }}
    />
  </Svg>
)
export default SectionFour
