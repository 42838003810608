import { StyleSheet, Text, View } from 'react-native'
import React from 'react'
import SectionTwo from '../assets/images/svg/SectionTwo'
import SectionFour from '../assets/images/svg/SectionFour';
import Section from '../assets/images/svg/Section';
import SectionThree from '../assets/images/svg/SectionThree';
import { useSelector } from 'react-redux';

const OurServices = () => {
   const uiTexts = require('../business-logic/ui-texts-english.json');
   const { layout } = useSelector(state => state.layoutSlice);
   const container = layout === 'portrait' ? styles.serviceContainerPortrait : styles.serviceContainerLandscape
	const width = layout === 'portrait' ? styles.widthPortrait : styles.widthNonPortrait;
	const titleText = layout === 'portrait' ? styles.titleTextPortrait : styles.titleTextNonPortrait;
   return (
      <View style={styles.flex}>
         <Text style={[titleText, styles.title]}>{uiTexts.Services.Title}</Text>
         <View style={container}>
            <View style={width}>
               <Section />
               <Text style={styles.text}>{uiTexts.Services.SectionOne}</Text>
            </View>
            <View style={width}>
               <SectionTwo />
               <Text style={styles.text}>{uiTexts.Services.SectionTwo}</Text>
            </View>
            <View style={width}>
               <SectionThree />
               <Text style={styles.text}>{uiTexts.Services.SectionThree}</Text>
            </View>
            <View style={width}>
               <SectionFour />
               <Text style={styles.text}>{uiTexts.Services.SectionFour}</Text>
            </View>
         </View>
      </View>
   )
}

export default OurServices

const styles = StyleSheet.create({
   flex: {
      flex: 1
   },
   title: {
      fontSize: 36,
      fontWeight: 'bold',
      color: '#19B892',
      marginVertical:12
   },
   serviceContainerPortrait: {
      flexDirection: 'column',
      alignItems: 'center',
   },
   serviceContainerLandscape: {
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent:'space-around'
   },
   titleTextPortrait: {
		textAlign: 'center',
	},
   titleTextNonPortrait: {
		textAlign: 'left',
	},
   text: {
      marginTop: 20,
      fontSize: 13,
      fontWeight: '500'
   },
	widthPortrait:{
		width: '90%'
	},
   widthNonPortrait: {
      width: 220
   }
})