/*

	-- install
	0.1  yarn add @reduxjs/toolkit react-redux
	0.2 Import in the App.js
			import { Provider } from 'react-redux';
			import store from './business-logic/store/store.js';
	0.3 Wrap the App.js's old return
			return <Provider store={store}>
				...
			</Provider>;

	-- ./business-logic/store directory

	1. Generate the related xxxSlice.js file
	2. In the xxxSlice.js file modify name field
	3. In the xxxSlice.js file modify initial state field
	4. In the xxxSlice.js modify reducer (state changer) name (here we use simplified states without history, if history is needed initial state should be array and in reducer (state changer) we need to "push" the new state)
	in the stateHandler, include initial state value
	5. In the xxxSlice.js modify first export with state changer name (from reducer field)
	6. In this file add an import of the default export of the xxxSlice.js file
	7. In this file add the import to the configureStore's reducer field as a new entry

	-- Presentation Layer

	8. To use the state import the step 5 export in the view layer.
			Ex:
				import { xxxxFromStep5 } from './store/xxxSlice'
	9. To use the state use the useSelector hook and name from configured store at step 7 in the presenting view layer.
			Ex: 
				const xxxxValue = useSelector(state => state.xxxNameFromStep2).state;
	10. To modify the data use the useDispatcher hook and state changer from step 8.
			Ex:
				const dispatch = useDispatch();
				dispatch(xxxxFromStep8(newValue));
*/

import { configureStore } from '@reduxjs/toolkit';

// Step 6
import widthSizeReducer from './widthSizeSlice.js';
import heightSizeReducer from './heightSizeSlice.js';
import layoutReducer from './layoutSlice.js'
export default configureStore({
	reducer: {
		// Step 7
		widthSize: widthSizeReducer,
		heightSize: heightSizeReducer,
		layoutSlice: layoutReducer,
	}
})
