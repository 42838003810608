import { createSlice } from '@reduxjs/toolkit';

const layoutSlice = createSlice({
  name: 'layoutSlice',
  initialState: {
    layout: '', 
  },
  reducers: {
    setLayout: (state, action) => {
      state.layout = action.payload; 
    },
  },
});

export const { setLayout } = layoutSlice.actions;
export default layoutSlice.reducer;
